import axios, { AxiosRequestConfig } from 'axios'
import type { RandGalleryListing } from './types'
import { RANDGALLERY_BASE_URL, RANDGALLERY_API_BASE_URL } from './constants'
import type { Listing } from 'api/hooks/useNFTListings'
import { convertAlgosToMicroalgos } from 'helpers/utilities'
import { AsaInfo, getASAInfo } from '../useNFTListings'

export const mapList = (list: RandGalleryListing[], asaInfo: AsaInfo[]): Listing[] => {
  return list.map((listing) => {
    const assetId = listing.assetId.toString()

    return {
      name: asaInfo.find((asa) => asa.asaID === listing.assetId)?.name || '',
      assetId,
      creatorAddress: listing.creatorAddress,
      price: convertAlgosToMicroalgos(listing.price),
      imageUrl: asaInfo.find((asa) => asa.asaID === listing.assetId)?.imageUrl || '',
      marketplace: 'RandGallery',
      marketplaceLink: `${RANDGALLERY_BASE_URL}/algo-collection/?address=${assetId}`
    }
  })
}

export const getRandGalleryList = async (
  address: string,
  ssr?: boolean,
  options?: AxiosRequestConfig
) => {
  const { data } = await axios.get<RandGalleryListing[]>(
    `${RANDGALLERY_API_BASE_URL}/v1/listings/seller/${address}`,
    {
      ...(ssr && { headers: { origin: process.env.NEXT_PUBLIC_BASE_URL as string } }),
      ...options
    }
  )

  if (!data || !data.length) {
    throw new Error('There are no RandGallery listings for this address')
  }

  const assetIds = data.map((asset) => asset.assetId)
  const asaInfo = await getASAInfo(assetIds, ssr, options)

  return mapList(data, asaInfo)
}
