import React, { useState } from 'react'
import AssetMedia from 'components/AssetMedia'
import LightBox from 'components/LightBox'
import galleryImageLoader from 'helpers/galleryImageLoader'

type AvatarProps = {
  asaId?: string
  imageSrc?: string
  alt?: string
  verified?: boolean
}

function Avatar({ asaId, imageSrc = '', alt = '', verified = false }: AvatarProps) {
  const [isOpen, setIsOpen] = useState(false)

  const handleClickOpen = () => {
    setIsOpen(true)
  }

  const handleClickClose = () => {
    setIsOpen(false)
  }

  const renderAvatar = () => {
    if (verified) {
      return (
        <button
          type="button"
          onClick={handleClickOpen}
          className="relative -top-2.5 -left-[0.25rem] sm:-left-[0.375rem] z-10 flex items-center justify-center rounded-full bg-gradient-to-br from-violet-600 to-pink-500 h-28 w-28 sm:h-[9.25rem] sm:w-[9.25rem] xl:h-[11.25rem] xl:w-[11.25rem] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 -mb-4 sm:-mb-5 xl:-mb-4"
        >
          <div className="flex relative rounded-full bg-white overflow-hidden h-24 w-24 ring-[3px] sm:ring-4 ring-white sm:h-32 sm:w-32 xl:h-40 xl:w-40 dark:bg-gray-900 dark:ring-gray-900">
            <AssetMedia
              src={imageSrc}
              alt={alt}
              className="object-cover w-full h-full"
              loader={galleryImageLoader}
              sizes="(max-width: 640px) 96px, (max-width: 1280px) 128px, 160px"
              fill
            />
          </div>
        </button>
      )
    }

    return (
      <button
        type="button"
        onClick={handleClickOpen}
        className="flex relative rounded-full bg-white overflow-hidden h-24 w-24 ring-4 ring-white sm:h-32 sm:w-32 xl:h-40 xl:w-40 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 xl:mb-1 dark:bg-gray-900 dark:ring-gray-900"
      >
        <AssetMedia
          src={imageSrc}
          alt={alt}
          className="object-cover w-full h-full"
          loader={galleryImageLoader}
          sizes="(max-width: 640px) 96px, (max-width: 1280px) 128px, 160px"
          fill
        />
      </button>
    )
  }

  return (
    <>
      {renderAvatar()}
      <LightBox
        asaId={asaId}
        isOpen={isOpen}
        closeModal={handleClickClose}
        imageUrl={imageSrc}
        imageAlt={alt}
      />
    </>
  )
}

export default React.memo(Avatar)
