import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import Link from 'next/link'
import { HiDotsVertical, HiPencil } from 'react-icons/hi'
import { Context } from 'components/DetailView/DetailView.types'
import { useExplorerStore } from 'store/index'
import { classNames, getNfdUrl, getTwitterShareLink } from 'helpers/utilities'
import { copyToClipboard } from 'helpers/copyToClipboard'
import type { NfdRecord } from 'api/api-client'

interface ProfileMenuProps {
  nfd: NfdRecord
  context?: Context
}

export default function ProfileMenu({ nfd, context }: ProfileMenuProps) {
  const selectedExplorer = useExplorerStore((state) => state.selectedExplorer)
  const lookupByAppId = useExplorerStore((state) => state.lookupByAppId)

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div className="flex items-center h-10">
        <Menu.Button className="bg-white rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-brand-500 dark:bg-gray-900 dark:text-gray-500 dark:hover:text-gray-400 dark:focus:ring-offset-gray-900">
          <span className="sr-only">Open menu</span>
          <HiDotsVertical className="h-6 w-6 sm:h-7 sm:w-7" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="z-[22] overflow-hidden origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none dark:bg-gray-800 dark:text-gray-400 dark:ring-gray-800/40 dark:divide-gray-750">
          {context === Context.Manage && (
            <div className="md:hidden">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    href={`/manage/${nfd.name}`}
                    className={classNames(
                      active ? 'from-brand-500 to-brand-600' : ' from-brand-400 to-brand-600',
                      'bg-gradient-to-r text-white group flex items-center px-4 py-2.5 text-sm'
                    )}
                  >
                    <HiPencil className="mr-3 h-5 w-5 text-white" aria-hidden="true" />
                    Edit
                  </Link>
                )}
              </Menu.Item>
            </div>
          )}
          <div className="py-1">
            {nfd.appID && (
              <Menu.Item>
                {({ active }) => (
                  <a
                    href={lookupByAppId(nfd.appID || '')}
                    className={classNames(
                      active
                        ? 'bg-gray-100 text-gray-900 dark:bg-gray-900/75 dark:text-gray-200'
                        : 'text-gray-700 dark:text-gray-400',
                      'block px-4 py-2 text-sm'
                    )}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View on {selectedExplorer}
                  </a>
                )}
              </Menu.Item>
            )}
            <Menu.Item>
              {({ active }) => (
                <a
                  href={`https://www.asalytic.app/wallet/${nfd.name}`}
                  className={classNames(
                    active
                      ? 'bg-gray-100 text-gray-900 dark:bg-gray-900/75 dark:text-gray-200'
                      : 'text-gray-700 dark:text-gray-400',
                    'block px-4 py-2 text-sm'
                  )}
                  target="_blank"
                  rel="noreferrer"
                >
                  View on Asalytic
                </a>
              )}
            </Menu.Item>
          </div>
          {/* @todo: add "Share" UI, and possibly "Add to favorites" */}
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  type="button"
                  className={classNames(
                    active
                      ? 'bg-gray-100 text-gray-900 dark:bg-gray-900/75 dark:text-gray-200'
                      : 'text-gray-700 dark:text-gray-400',
                    'block w-full text-left px-4 py-2 text-sm'
                  )}
                  data-clipboard-text={getNfdUrl(nfd.name)}
                  onClick={copyToClipboard}
                >
                  Copy profile URL
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href={getTwitterShareLink(nfd.name)}
                  target="_blank"
                  rel="noreferrer"
                  className={classNames(
                    active
                      ? 'bg-gray-100 text-gray-900 dark:bg-gray-900/75 dark:text-gray-200'
                      : 'text-gray-700 dark:text-gray-400',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  Share to Twitter
                </a>
              )}
            </Menu.Item>
          </div>

          {context === Context.Manage && (
            <div className="py-1 md:hidden">
              <Menu.Item>
                {() => (
                  <Link
                    href={`/sell/${nfd.name}`}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-900/75 dark:hover:text-gray-200"
                  >
                    Sell
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {() => (
                  <Link
                    href={`/transfer/${nfd.name}`}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-900/75 dark:hover:text-gray-200"
                  >
                    Transfer
                  </Link>
                )}
              </Menu.Item>
            </div>
          )}

          {context !== Context.Manage && (
            <div className="py-1 md:hidden">
              <Menu.Item>
                {() => (
                  <Link
                    href={`/offer/${nfd.name}`}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-900/75 dark:hover:text-gray-200"
                  >
                    Make an offer
                  </Link>
                )}
              </Menu.Item>
            </div>
          )}

          {context !== Context.Manage && (
            <div className="py-1">
              <Menu.Item>
                {() => (
                  <Link
                    href={`/report/${nfd.name}`}
                    className="block px-4 py-2 text-sm text-red-500 hover:bg-red-50 hover:text-red-700 dark:text-red-500 dark:hover:bg-gray-900/75 dark:hover:text-red-500"
                  >
                    Report
                  </Link>
                )}
              </Menu.Item>
            </div>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
