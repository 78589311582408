import Button from 'components/Button'

type ErrorFallbackProps = {
  error: Error
  resetErrorBoundary: (...args: unknown[]) => void
}

export default function ErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProps) {
  return (
    <div>
      <div className="flow-root bg-white shadow overflow-hidden sm:rounded-lg px-4 py-5 sm:px-6 mb-16 dark:bg-gray-900">
        <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
          <span className="font-bold text-brand-500">Error: </span>Failed to retrieve history
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500 mb-5 dark:text-gray-400">
          {error.message}
        </p>
        <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent">
          <Button variant="primary" onClick={resetErrorBoundary}>
            Try again
          </Button>
          <a href="mailto:feedback@txnlab.dev">
            <Button>Contact support</Button>
          </a>
        </div>
      </div>
    </div>
  )
}
