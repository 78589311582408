import axios, { AxiosRequestConfig } from 'axios'
import type { ShuflListing } from './types'
import { SHUFL_BASE_URL, SHUFL_API_BASE_URL } from './constants'
import type { Listing } from 'api/hooks/useNFTListings'
import { AsaInfo, getASAInfo } from '../useNFTListings'

export const mapList = (list: ShuflListing[], asaInfo: AsaInfo[]): Listing[] => {
  return list
    .filter((listing) => listing.price && listing.creator)
    .map((listing) => {
      return {
        name: listing.name,
        assetId: listing.id.toString(),
        creatorAddress: asaInfo.find((asa) => asa.asaID === listing.id)?.creator || '',
        price: Number(listing.price),
        imageUrl: asaInfo.find((asa) => asa.asaID === listing.id)?.imageUrl || '',
        marketplace: 'Shufl',
        marketplaceLink: `${SHUFL_BASE_URL}/detail/${listing.id}`
      }
    })
}

export const getShuflList = async (
  addresses: string[],
  ssr?: boolean,
  options?: AxiosRequestConfig
) => {
  const { data } = await axios.get<ShuflListing[]>(
    `${SHUFL_API_BASE_URL}/getListings?wallets=${JSON.stringify(addresses)}&traits=false`,
    {
      ...(ssr && { headers: { origin: process.env.NEXT_PUBLIC_BASE_URL as string } }),
      ...options
    }
  )

  if (!data || !data.length) {
    throw new Error('There are no Shufl listings for this address')
  }

  const assetIds = data.map((asset) => asset.id)
  const asaInfo = await getASAInfo(assetIds, ssr, options)

  return mapList(data, asaInfo)
}
