import axios, { AxiosRequestConfig } from 'axios'
import { AsaInfo, getASAInfo } from '../useNFTListings'
import { EXA_BASE_URL } from './constants'
import type { Basket, ExaListing } from './types'
import type { Listing } from 'api/hooks/useNFTListings'

export const mapListings = (list: ExaListing[], asaInfo: AsaInfo[]): Listing[] => {
  const getWinningBid = (bids: Basket['bids']) => {
    if (!bids.length) {
      return 0
    }

    const highestBid = bids.sort((a, b) => parseInt(b.quantity) - parseInt(a.quantity))[0]

    return parseInt(highestBid.quantity)
  }

  return list.map((listing) => {
    const mapped: Listing = {
      name: asaInfo.find((asa) => asa.asaID === listing.assetId)?.name || '',
      assetId: listing.assetId.toString(),
      creatorAddress: listing.creator,
      price: parseInt(listing.basket.priceAsset.quantity),
      priceAsset: asaInfo.find((asa) => asa.asaID === listing.basket.priceAsset.asset.assetId),
      imageUrl: asaInfo.find((asa) => asa.asaID === listing.assetId)?.imageUrl || '',
      marketplace: 'Exa',
      marketplaceLink: `${EXA_BASE_URL}/basket/${listing.basket.appId}`,
      basketSize: listing.basket.forSaleAssets.length
    }

    if (listing.basket.isAuction) {
      // uses schema already established by AlgoX auction listings
      mapped.auction = {
        startingBid: parseInt(listing.basket.priceAsset.quantity),
        winningBid: getWinningBid(listing.basket.bids),
        endingTime: listing.basket.endTime || ''
      }
    }

    return mapped
  })
}

const convertBasketsToListings = (baskets: Basket[]) => {
  return baskets.reduce((acc, basket) => {
    const forSaleAssets = basket.forSaleAssets
      .filter((basketAsset) => basketAsset.quantity === '1') // only NFTs
      .map((basketAsset) => ({
        ...basketAsset.asset,
        basket
      }))

    acc.push(...forSaleAssets)

    return acc
  }, [] as ExaListing[])
}

export const getExaList = async (address: string, ssr?: boolean, options?: AxiosRequestConfig) => {
  const walletAddress = address

  // 1st page (page 0)
  const initialFetch = await axios.get('/api/exa', {
    params: {
      walletAddress
    }
  })

  if (!initialFetch.data) {
    throw new Error('Error fetching Exa listings for this address')
  }

  const { total, items: baskets } = initialFetch.data

  if (!baskets.length || total === 0) {
    throw new Error('There are no Exa listings for this address')
  }

  let page = 1

  // fetch all remaining pages (if any)
  while (baskets.length < total) {
    const res = await axios.get('/api/exa', {
      params: { walletAddress, page }
    })

    baskets.push(...res.data.items)
    page += 1
  }

  // crack open baskets to get supported listings
  const listings = convertBasketsToListings(baskets)

  const assetIds = listings.map((listing) => listing.assetId)
  const priceAssetIds = listings.map((listing) => listing.basket.priceAsset.asset.assetId)
  const ids = [...new Set([...assetIds, ...priceAssetIds])]

  const asaInfo = await getASAInfo(ids, ssr, options)

  return mapListings(listings, asaInfo)
}
