import { isSegmentsSupported } from 'helpers/versions'
import { isValidRoot } from 'helpers/utilities'
import type { NfdRecord } from 'api/api-client'
import type { Sections } from 'components/DetailView/DetailView.types'

export const isTabEnabled = (nfd: NfdRecord, tab: Sections) => {
  switch (tab) {
    case 'segments':
      return isSegmentsSupported(nfd) && isValidRoot(nfd.name)
    case 'vault':
      return false
    case 'gallery':
      return false
    default:
      return true
  }
}
