import { useQueryClient } from '@tanstack/react-query'
import { useWallet } from '@txnlab/use-wallet-react'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import * as React from 'react'
import toast from 'react-hot-toast'
import { HiCalendarDays, HiSparkles } from 'react-icons/hi2'
import { useNfdConstraints } from '@/api/hooks/contracts/useNfdConstraints'
import { useGetQuote } from '@/api/hooks/useGetQuote'
import { usePostMint } from '@/api/hooks/usePostMint'
import Alert from '@/components/Alert'
import AlgoPrice from '@/components/AlgoPrice'
import Button from '@/components/Button'
import { Loading } from '@/components/LoadingSpinner'
import Modal, { ModalFooter } from '@/components/Modal'
import UsdPrice from '@/components/UsdPrice'
import { ZERO_ADDRESS } from '@/data/constants'
import { checkBalance } from '@/helpers/checkBalance'
import { formatPrice } from '@/helpers/utilities'
import useErrorToast from '@/hooks/useErrorToast'
import { useMintPolling } from '@/hooks/useMintPolling'
import usePush from '@/hooks/usePush'
import { useNocacheStore } from '@/store/index'
import { MintSlider } from './MintSlider'

dayjs.extend(localizedFormat)

interface MintDialogProps {
  name: string
  disabled?: boolean
  children?: React.ReactElement<{ onClick?: () => void; disabled?: boolean }>
}

export default function MintDialog({ name, children, disabled }: MintDialogProps) {
  const [isOpen, setIsOpen] = React.useState(false)
  const [years, setYears] = React.useState(1)
  const [isPending, setIsPending] = React.useState(false)

  const { activeAddress } = useWallet()
  const queryClient = useQueryClient()
  const push = usePush()
  const handleError = useErrorToast()
  const toastId = React.useId()

  const addNameNocache = useNocacheStore((state) => state.addName)

  const { startPolling, status, error } = useMintPolling(name, 'nfdMinted')
  const isDisabled = isPending || status === 'polling'

  const quoteQuery = useGetQuote({
    name,
    params: {
      buyer: activeAddress || ZERO_ADDRESS
    },
    options: {
      enabled: isOpen && !isPending
    }
  })

  const constraintsQuery = useNfdConstraints()
  const constraints = constraintsQuery.data

  const priceOneYear = quoteQuery.data?.price || 0
  const carryCost = quoteQuery.data?.carryCost || 0
  const maxYearsAllowed = constraints?.maxYearsAllowed || 0

  const totalPrice = React.useMemo(() => {
    const pricePerYear = priceOneYear - carryCost
    return pricePerYear * years + carryCost
  }, [carryCost, priceOneYear, years])

  const mintNfd = usePostMint({
    onSuccess: (data, params) => {
      queryClient.invalidateQueries({ queryKey: ['name', params.name] })
      toast.loading('Loading profile...', { id: toastId })
      startPolling()
      handleClose()
    },
    onError: (error) => {
      handleError(error)
    },
    onSettled: () => {
      setIsPending(false)
    },
    toasts: {
      success: `Success! Minted ${name}`
    }
  })

  React.useEffect(() => {
    const handleNfdMinted = (event: CustomEvent) => {
      if (event.detail.name === name) {
        addNameNocache(name)
        const pathname = `/name/${name}`
        const params = {
          pathname,
          query: { purchased: true } // show confetti 🎉
        }
        push(params, pathname)
        toast.dismiss(toastId)
      }
    }

    window.addEventListener('nfdMinted', handleNfdMinted as EventListener)
    return () => {
      window.removeEventListener('nfdMinted', handleNfdMinted as EventListener)
    }
  }, [addNameNocache, name, push, toastId])

  React.useEffect(() => {
    if (status === 'error' && error) {
      handleError(error, { toastId })
    }
  }, [error, handleError, status, toastId])

  const handleMint = async () => {
    try {
      if (!activeAddress) {
        throw new Error('Wallet not connected')
      }

      const balance = await checkBalance(activeAddress, totalPrice)

      if (!balance.hasSufficientBalance) {
        throw new Error(
          `Insufficient available balance. A minimum available balance of ${formatPrice(
            balance.balanceRequired as number,
            false,
            { maximumFractionDigits: 6 }
          )} ALGO is required to complete this transaction.`
        )
      }

      setIsPending(true)

      const payload = {
        buyer: activeAddress,
        name,
        years
      }

      mintNfd.mutate(payload)
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An error occurred'
      toast.error(errorMessage)
    }
  }

  const handleClose = () => {
    setIsOpen(false)
    setTimeout(() => {
      setYears(1)
    }, 300)
  }

  const renderMintSlider = () => {
    if (quoteQuery.isLoading || constraintsQuery.isLoading) {
      return <Loading />
    }

    if (quoteQuery.error || constraintsQuery.error) {
      return (
        <div className="py-8 text-left">
          <Alert
            type="error"
            title="Error loading price data"
            message={
              quoteQuery.error?.message || constraintsQuery.error?.message || 'Unknown error'
            }
          />
        </div>
      )
    }

    return (
      <div className="mt-10 sm:mt-8">
        <div className="flex items-center justify-between flex-wrap">
          <p className="text-2xl/8 font-display font-medium xs:text-3xl/8 text-gray-700 dark:text-gray-300">
            {years} year{years > 1 ? 's' : ''}
          </p>
          <div className="text-2xl/8 font-display font-semibold xs:text-3xl/8 text-gray-900 dark:text-gray-100">
            <AlgoPrice price={totalPrice} />
          </div>
        </div>
        <div className="xs:mt-2">
          <div>
            <MintSlider
              value={years}
              onChange={setYears}
              max={maxYearsAllowed}
              disabled={isPending}
            />
          </div>
        </div>
        <div className="pb-8 text-left">
          <dl className="flex items-center justify-between flex-wrap gap-x-4 gap-y-2">
            <div className="flex gap-x-4">
              <dt className="flex-none">
                <span className="sr-only">Expires</span>
                <HiCalendarDays
                  aria-hidden="true"
                  className="h-6 w-5 text-gray-400 dark:text-gray-500"
                />
              </dt>
              <dd className="text-sm leading-6 text-gray-500 dark:text-gray-400">
                <time dateTime={dayjs().add(years, 'years').format('YYYY-MM-DD')}>
                  {dayjs().add(years, 'years').format('LL')}
                </time>
              </dd>
            </div>
            <div className="flex gap-x-4">
              <dd className="text-right text-sm leading-6 text-gray-500 dark:text-gray-400">
                <UsdPrice
                  price={totalPrice}
                  className="text-sm leading-6 text-gray-500 dark:text-gray-400"
                />
              </dd>
            </div>
          </dl>
        </div>
      </div>
    )
  }

  const renderTrigger = () => {
    if (React.isValidElement(children)) {
      return React.cloneElement(children, {
        onClick: () => setIsOpen(true),
        disabled: isDisabled || disabled === true
      })
    }

    return (
      <>
        <Button
          size="md"
          variant="gradient"
          onClick={() => setIsOpen(true)}
          disabled={isDisabled}
          className="xs:hidden"
        >
          Mint
        </Button>
        <Button
          size="lg"
          variant="gradient"
          onClick={() => setIsOpen(true)}
          disabled={isDisabled}
          className="hidden px-5 xs:inline-flex"
        >
          Mint
        </Button>
      </>
    )
  }

  return (
    <>
      {renderTrigger()}

      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClose={handleClose}
        title={
          <span
            className="break-words"
            style={
              {
                ['--name-length' as string]: `${name.length - 5}ch`,
                ['--suffix-length' as string]: '5ch',
                wordBreak: 'break-word',
                wordWrap: 'break-word',
                maxWidth: 'calc(100% - var(--suffix-length))'
              } as React.CSSProperties
            }
          >
            Mint {name.slice(0, -5)}
            <span className="inline-block whitespace-nowrap">.algo</span>
          </span>
        }
        icon={HiSparkles}
        className="max-w-screen-sm"
        manualClose={isPending}
        showX={false}
      >
        {renderMintSlider()}

        <ModalFooter className="mt-8">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-brand-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-brand-600 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-50 disabled:bg-brand-500 dark:bg-brand-600 dark:hover:bg-brand-500 dark:focus:ring-offset-gray-900 dark:ring-gray-100 dark:disabled:opacity-25 dark:disabled:bg-brand-600"
            disabled={isDisabled}
            onClick={handleMint}
          >
            {isPending ? 'Minting...' : 'Mint NFD'}
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm dark:bg-gray-750 dark:border-transparent dark:text-gray-300 dark:hover:bg-gray-700 dark:focus:ring-offset-gray-900"
            onClick={handleClose}
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
    </>
  )
}
