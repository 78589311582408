import { useMemo } from 'react'
import Link from 'next/link'
import AlgoAddress from 'components/AlgoAddress'
import CryptoAddress from 'components/CryptoAddress'
import { Context } from 'components/DetailView/DetailView.types'
import type { NfdRecord } from 'api/api-client'

interface UnverifiedAddressCardProps {
  nfd: NfdRecord
  context: Context
}

export default function UnverifiedAddressCard({ nfd, context }: UnverifiedAddressCardProps) {
  const { unverifiedCaAlgo = [], unverifiedCa = {} } = nfd

  const showCard = useMemo(() => {
    const unverifiedArr = Object.keys(unverifiedCa).reduce((acc, key) => {
      return [...acc, ...(unverifiedCa[key] || [])]
    }, [] as string[])
    return [...unverifiedCaAlgo, ...unverifiedArr].length > 0
  }, [unverifiedCa, unverifiedCaAlgo])

  const showCrypto = Object.keys(unverifiedCa).length > 0
  const showAlgo = unverifiedCaAlgo.length > 0

  if (!showCard) {
    return null
  }

  const renderAlgoAddresses = () => {
    return unverifiedCaAlgo.map((address) => (
      <li key={address} className="min-w-0 px-5 py-2 flex items-center text-sm">
        <AlgoAddress
          address={address}
          copy
          link
          logo
          expand
          isDeposit={address === nfd.depositAccount}
        />
      </li>
    ))
  }

  const renderCryptoAddresses = () => {
    return Object.keys(unverifiedCa).map((key) => {
      return unverifiedCa[key].map((address) => (
        <li key={address} className="min-w-0 px-5 py-2 flex items-center text-sm">
          <CryptoAddress address={address} symbol={key} copy expand />
        </li>
      ))
    })
  }

  const renderList = (list: 'algo' | 'crypto') => {
    return (
      <ul
        role="list"
        className="max-w-full bg-white divide-y divide-gray-200 dark:bg-gray-850 dark:divide-gray-850"
      >
        {list === 'algo' ? renderAlgoAddresses() : renderCryptoAddresses()}
      </ul>
    )
  }

  return (
    <div className="break-inside-avoid-column">
      <div className="bg-white shadow overflow-hidden rounded-lg dark:bg-gray-850 dark:highlight">
        <div className="py-4 border-b border-gray-200 px-4 sm:px-6 dark:border-gray-750/75">
          <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div className="ml-4 mt-2">
              <h3 className="text-md leading-6 font-medium text-gray-900 dark:text-gray-300">
                Unverified Addresses
              </h3>
            </div>
            <div className="ml-4 mt-2 flex-shrink-0">
              {context === Context.Manage && (
                <Link
                  href={`/manage/${nfd.name}?view=addresses&tab=unverified_addresses`}
                  className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-brand-500 hover:bg-brand-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 -my-1"
                >
                  Manage
                </Link>
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="text-sm text-gray-900 space-y-4">
            {showAlgo && renderList('algo')}
            {showCrypto && renderList('crypto')}
          </div>
        </div>
      </div>
    </div>
  )
}
