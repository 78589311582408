import { Fragment } from 'react'
import { InView } from 'react-intersection-observer'
import { HiSearch, HiX } from 'react-icons/hi'
import ItemLayout from 'components/ItemLayout'
import Loading from 'components/Loading'
import LightBox from 'components/LightBox'
import AssetOwned from './AssetOwned'
import AssetForSale from './AssetForSale'
import useGallery from './Gallery.hooks'
import { getNfdHasDefaultAvatar } from './Gallery.utils'
import type { Asset } from './Gallery.types'
import Alert from 'components/Alert'
// import GalleryToggle from 'components/GalleryToggle'
import type { AxiosError } from 'axios'
import Toggle from 'components/Toggle'
import SupportedMarketplaces from './SupportedMarketplaces'
import type { PanelProps } from 'components/DetailView/DetailView.types'

export default function Gallery({ nfd, context }: PanelProps) {
  const {
    lightbox,
    nfts,
    nftsTotal,
    listingsQuery,
    viewToggle,
    // handleChangeToggle,
    handleOpenLightbox,
    handleCloseLightbox,
    handleAdvanceLightboxImage,
    showNFDsInGallery,
    handleToggleShowNFDs,
    showPrevNext,
    query,
    queryKey,
    handleQueryChange,
    clearQuery,
    isLoading,
    error,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage
  } = useGallery(nfd)

  const renderOwnedResult = (asset: Asset, pageIdx: number, i: number) => {
    const nfdName = getNfdHasDefaultAvatar(asset) ? asset.name : undefined

    return (
      <AssetOwned
        key={asset.asaID}
        asset={asset}
        nfd={nfd}
        context={context}
        onImageClick={() =>
          handleOpenLightbox(
            asset.imageUrl,
            `ASA ${asset.asaID.toString()}`,
            asset.asaID.toString(),
            nfdName
          )
        }
        queryKey={queryKey}
        pageIndex={pageIdx}
        index={i}
      />
    )
  }

  const renderOwnedResults = (results: Asset[], pageIdx: number) => {
    if (!nfts) {
      return null
    }

    const handleInViewChange = (inView: boolean) => {
      if (!inView) return

      const isLastPage = pageIdx === nfts.pages.length - 1

      if (isLastPage && hasNextPage) {
        fetchNextPage()
      }
    }

    return results.map((asset: Asset, i) => {
      const isLastResult = i === results.length - 1

      if (isLastResult) {
        return (
          <InView key={asset.asaID} as="div" onChange={handleInViewChange}>
            {renderOwnedResult(asset, pageIdx, i)}
          </InView>
        )
      }

      return renderOwnedResult(asset, pageIdx, i)
    })
  }

  const renderGallery = () => {
    if (isLoading) {
      return (
        <div className="flex flex-col items-center justify-center py-32">
          <Loading />
        </div>
      )
    }

    if (error) {
      return (
        <div className="py-8">
          <Alert type="error" title={`Error fetching gallery assets`} error={error} />
        </div>
      )
    }

    if (!isFetchingNextPage && nftsTotal === 0) {
      return (
        <div className="py-8">
          <Alert type="info" title="No NFTs found" />
        </div>
      )
    }

    return (
      <>
        <ItemLayout view="grid">
          {nfts?.pages.map((page, pageIdx) => (
            <Fragment key={pageIdx}>{renderOwnedResults(page.results, pageIdx)}</Fragment>
          ))}
        </ItemLayout>

        {isFetchingNextPage && (
          <div className="flex flex-col items-center justify-center py-32">
            <Loading />
          </div>
        )}
      </>
    )
  }

  const renderListings = () => {
    if (listingsQuery.isInitialLoading) {
      return (
        <div className="flex flex-col items-center justify-center py-32">
          <Loading />
        </div>
      )
    }

    if (listingsQuery.error) {
      return (
        <div className="py-8">
          <Alert type="error" title={`Error fetching NFTs for sale`} error={error as AxiosError} />
        </div>
      )
    }

    if (!listingsQuery.listings || listingsQuery.listings.length === 0) {
      return (
        <div className="py-8">
          <Alert type="info" title="No NFTs found" />
        </div>
      )
    }

    return (
      <ItemLayout view="grid">
        {listingsQuery.listings.map((listing, idx) => {
          const isNfd = listing.unitName?.toLowerCase() === 'nfd'
          const nfdName = isNfd ? listing.name : undefined

          return (
            <div key={`${listing.assetId}-${idx}`}>
              <AssetForSale
                link={listing.marketplaceLink}
                name={listing.name}
                nfdName={nfdName}
                marketplace={listing.marketplace}
                assetId={listing.assetId}
                imageSrc={listing.imageUrl}
                creatorAddress={listing.creatorAddress}
                price={listing.price}
                priceAsset={listing.priceAsset}
                auction={listing.auction}
                basketSize={listing.basketSize}
                onImageClick={() => {
                  handleOpenLightbox(
                    listing.imageUrl,
                    `listing for ${listing.name})`,
                    listing.assetId,
                    nfdName
                  )
                }}
              />
            </div>
          )
        })}
      </ItemLayout>
    )
  }

  return (
    <div className="flex-1 bg-gray-100 pt-2 pb-8 5xl:pt-4 dark:bg-gray-950/40">
      <div className="p-4 sm:px-6 lg:px-8 5xl:px-10" id="parent">
        <div className="bg-white shadow sm:rounded-lg dark:bg-gray-850 dark:highlight">
          <div className="flex justify-between items-center px-4 py-5 sm:px-6 xl:space-x-4">
            <div className="hidden xl:inline-block">
              <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
                NFT Gallery
              </h3>
              <p className="hidden 2xl:block mt-1 max-w-2xl text-sm text-gray-500 dark:text-gray-500">
                NFTs for{' '}
                <strong className="text-gray-600 font-semibold dark:text-gray-300">
                  {nfd.name}
                </strong>{' '}
                and all linked accounts
              </p>
            </div>
            <div className="w-full xl:w-auto flex flex-col md:flex-row md:items-center justify-between md:space-x-4">
              <div className="flex flex-col-reverse md:flex-row md:items-center justify-between md:space-x-4">
                <div className="my-4 w-full md:my-0 md:max-w-[18rem] xl:w-80 relative rounded-md shadow-sm text-gray-400 focus-within:text-gray-600 dark:text-gray-600 dark:focus-within:text-gray-400">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <HiSearch className="h-5 w-5" aria-hidden="true" />
                  </div>
                  <input
                    type="text"
                    name="gallery-search"
                    id="gallery-search"
                    className="focus:ring-brand-500 focus:border-brand-500 block w-full px-10 sm:text-sm border-gray-300 rounded-md dark:bg-gray-750/75 dark:border-transparent dark:text-gray-100 dark:focus:border-brand-500 dark:placeholder-gray-500 dark:caret-gray-400"
                    placeholder="Search name or ASA ID"
                    value={query}
                    onChange={handleQueryChange}
                  />
                  {query.length > 0 && (
                    <div className="absolute inset-y-0 right-2 flex items-center">
                      <button
                        type="button"
                        className="inline-flex items-center justify-center border border-transparent rounded-full text-gray-400 bg-white hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 focus:text-gray-600 dark:bg-transparent dark:text-gray-500 dark:hover:text-gray-400 dark:focus:ring-offset-gray-800"
                        onClick={clearQuery}
                      >
                        <HiX className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  )}
                </div>
                {/* <GalleryToggle value={viewToggle} onChange={handleChangeToggle} /> */}
              </div>
              <Toggle
                className="mt-2 md:mt-0 md:ml-4"
                label={'Show NFDs'}
                checked={showNFDsInGallery}
                onChange={handleToggleShowNFDs}
              />
            </div>
          </div>
          <div className="border-t border-gray-200 dark:border-gray-750/75">
            <div className="px-4 py-5 sm:px-6">
              {viewToggle === 'owned' && renderGallery()}
              {viewToggle === 'for-sale' && (
                <>
                  <SupportedMarketplaces />
                  {renderListings()}
                </>
              )}
              {viewToggle === 'creations' && renderGallery()}
              <LightBox
                key={lightbox.asaId}
                isOpen={lightbox.isOpen}
                closeModal={handleCloseLightbox}
                imageUrl={lightbox.imageUrl}
                imageAlt={lightbox.imageAlt}
                asaId={lightbox.asaId}
                handleAdvanceLightboxImage={handleAdvanceLightboxImage}
                showPrevNext={showPrevNext}
                nfdName={lightbox.nfdName}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
