import SalesHistory from 'components/SalesHistory'
import type { PanelProps } from 'components/DetailView/DetailView.types'

export default function Sales({ nfd, context: _context }: PanelProps) {
  return (
    <div className="flex-1 bg-gray-100 pt-2 pb-8 5xl:pt-4 dark:bg-gray-950/40">
      <div className="max-w-5xl mx-auto p-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow overflow-hidden sm:rounded-lg dark:bg-gray-850 dark:highlight">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
              Sales
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500 dark:text-gray-500">
              Sales history for this NFD
            </p>
          </div>
          <div className="border-t border-gray-200 dark:border-gray-750/75">
            <SalesHistory name={nfd.name} profileTab />
          </div>
        </div>
      </div>
    </div>
  )
}
