import dynamic from 'next/dynamic'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { CSSTransition } from 'react-transition-group'
import AssetMedia from 'components/AssetMedia'
import TextAvatar from 'components/TextAvatar'
import UserThumbnail from 'components/UserThumbnail'
import AccountsBadge from './AccountsBadge'
import AssetMenu from './AssetMenu'
import AssetDetails from './AssetDetails'
import TokenImage from './TokenImage'
import { useAssetPreview, useAvatarBannerModal } from './AssetPreview.hooks'
import { showTextAvatar } from './AssetPreview.utils'
import galleryImageLoader from 'helpers/galleryImageLoader'
import { classNames, formatAssetBalance } from 'helpers/utilities'
import type { NfdRecord } from 'api/api-client'
import type { Asset } from 'types/assets'

const AvatarBannerModal = dynamic(() => import('./AvatarBannerModal'))
const SendModal = dynamic(() => import('components/SendModal'))

interface AssetPreviewProps {
  asset: Asset
  nfd: NfdRecord
  view: 'grid' | 'list'
  showAccounts?: boolean
}

function AssetPreview({ asset, nfd, view, showAccounts = false }: AssetPreviewProps) {
  const {
    isList,
    badgeRef,
    listBadgeRef,
    isDetailsOpen,
    isAccountsOpen,
    isSendModalOpen,
    setIsSendModalOpen,
    senders,
    receiver,
    receiverType,
    handleOpenSendModal,
    handleSendOptimisticUpdate,
    handleClickMoveToVault,
    handleClickMoveToDepositAccount,
    handleResetReceiver,
    handleOpenDetails,
    handleClickAccounts,
    handleClickFilterAccount,
    canSendAsset,
    canMoveToVault,
    canMoveToDepositAccount,
    showAccountsBadge,
    totalAmount,
    isAssetNft
  } = useAssetPreview({ nfd, asset, view })

  const {
    isModalOpen,
    setIsModalOpen,
    field,
    isUpdating,
    reset,
    handleClickVerify,
    handleClickSetField,
    canSetField
  } = useAvatarBannerModal({ nfd, asset })

  const renderMenu = () => {
    return (
      <AssetMenu
        asset={asset}
        isNft={isAssetNft}
        handleClickViewDetails={handleOpenDetails}
        handleClickSetField={handleClickSetField}
        handleClickSendAsset={handleOpenSendModal}
        handleClickMoveToVault={handleClickMoveToVault}
        handleClickMoveToDepositAccount={handleClickMoveToDepositAccount}
        canSetField={canSetField}
        canSendAsset={canSendAsset}
        canMoveToVault={canMoveToVault}
        canMoveToDepositAccount={canMoveToDepositAccount}
      />
    )
  }

  const renderDetails = () => {
    return (
      <AssetDetails
        open={isDetailsOpen}
        setOpen={handleOpenDetails}
        nfd={nfd}
        asset={asset}
        isNft={isAssetNft}
        isAccountsOpen={isAccountsOpen}
        totalAmount={totalAmount}
        handleClickSetField={handleClickSetField}
        handleClickSendAsset={handleOpenSendModal}
        handleClickMoveToVault={handleClickMoveToVault}
        handleClickMoveToDepositAccount={handleClickMoveToDepositAccount}
        handleClickFilterAccount={handleClickFilterAccount}
        canSetField={canSetField}
        canSendAsset={canSendAsset}
        canMoveToVault={canMoveToVault}
        canMoveToDepositAccount={canMoveToDepositAccount}
      />
    )
  }

  const renderAvatarBannerModal = () => {
    return (
      <AvatarBannerModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        field={field}
        asset={asset}
        isUpdating={isUpdating}
        reset={reset}
        handleClickVerify={handleClickVerify}
      />
    )
  }

  const renderSendModal = () => {
    if (!canSendAsset || !senders.length) return null

    return (
      <SendModal
        key={receiver || ''}
        isOpen={isSendModalOpen}
        setIsOpen={setIsSendModalOpen}
        asset={asset.id}
        sender={senders}
        receiver={receiver}
        receiverType={receiverType}
        showReceiver={!!receiver}
        onSuccess={handleSendOptimisticUpdate}
        onClose={handleResetReceiver}
      />
    )
  }

  const renderGridInfo = () => {
    const wrapperClassName = classNames(isList ? 'hidden' : 'mt-3')

    return (
      <div className={wrapperClassName}>
        <h3 className="truncate text-sm font-medium text-gray-900 dark:text-gray-100">
          {asset.name}
        </h3>
        <div className="mt-1.5 h-7 flex items-center justify-between text-sm">
          <div className="w-4/5 flex items-center">
            {isAssetNft ? (
              <UserThumbnail
                address={asset.creator}
                size="sm"
                className="text-gray-400 truncate"
                forceAddressFallback={asset.unitName === 'NFD'}
                fallbackClassName="text-gray-400 truncate dark:text-gray-400/80"
                wrapperClassName="flex items-center min-w-0"
                staleTime={Infinity}
              />
            ) : (
              <span className="text-gray-500 font-mono">{asset.unitName}</span>
            )}
          </div>
          {renderMenu()}
        </div>
      </div>
    )
  }

  const renderListInfo = () => {
    const gridTemplate = classNames(
      'grid-cols-1',
      'md:grid-cols-[3fr_1fr_2fr_48px]',
      'xl:grid-cols-[2fr_1fr_96px_1fr_24px]',
      '2xl:grid-cols-[2fr_1.5fr_1fr_1fr_140px_24px]',
      '4xl:grid-cols-[1.5fr_1fr_0.75fr_0.75fr_140px_48px]'
    )

    const wrapperClassName = classNames(isList ? 'flex-1 grid gap-x-6' : 'hidden', gridTemplate)

    return (
      <div className={wrapperClassName}>
        <div className="flex items-center min-w-0">
          <div className="flex-1 text-sm 4xl:text-base 4xl:pl-1 truncate">
            <span
              className="cursor-pointer hover:text-brand-500"
              onClick={() => handleOpenDetails(true)}
            >
              {asset.name}
            </span>
            <dl className="font-normal md:hidden">
              <dt className="sr-only">Quantity</dt>
              <dd className="truncate font-mono text-gray-700 dark:text-gray-400">
                <span>{formatAssetBalance(totalAmount, asset.decimals, true, true, 12)}</span>
                <span className="ml-2 text-gray-500">{asset.unitName}</span>
              </dd>
            </dl>
          </div>
          <div className="hidden xs:flex w-20 xl:w-24 4xl:w-32">
            {showAccounts && (
              // @ts-expect-error Not a valid JSX element
              <CSSTransition
                in={showAccountsBadge}
                nodeRef={listBadgeRef}
                timeout={500}
                classNames="accounts-badge"
                unmountOnExit
              >
                <AccountsBadge
                  ref={listBadgeRef}
                  nfd={nfd}
                  asset={asset}
                  onClick={handleClickAccounts}
                  className="rounded-lg xl:rounded-sm"
                  lightStyles
                  compact
                />
              </CSSTransition>
            )}
          </div>
          <div className="flex items-center pl-2 md:hidden">{renderMenu()}</div>
        </div>
        <div className="hidden 2xl:flex items-center truncate">
          {isAssetNft && (
            <UserThumbnail
              address={asset.creator}
              size="sm"
              className="text-gray-400 truncate"
              forceAddressFallback={asset.unitName === 'NFD'}
              fallbackClassName="text-gray-400 truncate dark:text-gray-400/80"
              wrapperClassName="flex items-center min-w-0"
              staleTime={Infinity}
            />
          )}
        </div>
        <div className="hidden md:flex items-center text-sm 4xl:text-base font-mono text-gray-500 dark:text-gray-400">
          {asset.unitName}
        </div>
        <div className="hidden xl:flex items-center text-sm 4xl:text-base font-mono text-gray-500 dark:text-gray-400">
          {asset.id > 0 ? asset.id : <span className="text-gray-400 dark:text-gray-500">--</span>}
        </div>
        <div className="hidden md:flex items-center justify-end text-sm 4xl:text-base font-mono text-gray-900 dark:text-gray-100">
          {formatAssetBalance(totalAmount, asset.decimals, true, true, 12)}
        </div>
        <div className="hidden md:flex items-center justify-center -my-1.5">{renderMenu()}</div>
      </div>
    )
  }

  const renderPreview = () => {
    if (!isList && showTextAvatar(asset)) {
      return (
        <TextAvatar
          name={asset.name}
          imgSrc={asset.imageUrl}
          className="absolute inset-0 object-cover w-full h-full"
        />
      )
    }

    if (!isAssetNft && !asset.imageUrl) {
      return <TokenImage />
    }

    return (
      <AssetMedia
        src={asset.imageUrl}
        alt={asset.id.toString()}
        className="object-cover w-full h-full"
        loader={galleryImageLoader}
        sizes="(max-width: 640px) 100vw, 360px"
        fill
        options={{ optimizeGifs: true, stateBgColor: 'bg-gray-500/5 dark:bg-gray-500/5' }}
        videoJsOptions={{
          preload: isMobile ? 'auto' : 'metadata',
          controls: isMobile,
          fluid: true,
          fill: true
        }}
      />
    )
  }

  const wrapperClassName = classNames(
    isList
      ? 'flex items-center px-4 sm:px-6 xl:px-8 hover:bg-gray-500/5 dark:hover:bg-gray-500/5 transition-[background-color]'
      : ''
  )

  return (
    <>
      <div key={asset.id} className={wrapperClassName}>
        <div className={classNames(isList ? 'py-3 pr-3' : '')}>
          <button
            type="button"
            className={classNames(
              isList
                ? 'w-12 rounded-full'
                : 'w-full rounded-lg hover:scale-[1.02] transition hover:shadow-md origin-bottom [-webkit-transform-style:preserve-3d]',
              'relative aspect-square overflow-hidden focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-500'
            )}
            onClick={() => handleOpenDetails(true)}
          >
            {renderPreview()}

            {!isList && showAccounts && (
              // @ts-expect-error Not a valid JSX element
              <CSSTransition
                in={showAccountsBadge}
                nodeRef={badgeRef}
                timeout={500}
                classNames="accounts-badge"
                unmountOnExit
              >
                <div className="absolute top-0 left-0 xl:top-1 xl:left-1">
                  <AccountsBadge
                    ref={badgeRef}
                    nfd={nfd}
                    asset={asset}
                    onClick={handleClickAccounts}
                    className="rounded-lg xl:rounded-sm"
                  />
                </div>
              </CSSTransition>
            )}
          </button>
        </div>
        {renderGridInfo()}
        {renderListInfo()}
      </div>

      {renderDetails()}
      {renderSendModal()}
      {renderAvatarBannerModal()}
    </>
  )
}

function propsAreEqual(prevProps: AssetPreviewProps, nextProps: AssetPreviewProps) {
  const { asset, view } = nextProps

  if (prevProps.asset.id !== asset.id || prevProps.view !== view) {
    return false
  }

  // Compare lengths of amounts array
  if (prevProps.asset.amounts.length !== asset.amounts.length) {
    return false
  }

  // Deep compare amounts array
  for (let i = 0; i < asset.amounts.length; i++) {
    if (
      prevProps.asset.amounts[i].account !== asset.amounts[i].account ||
      prevProps.asset.amounts[i].amount !== asset.amounts[i].amount
    ) {
      return false
    }
  }

  return true
}

export default React.memo(AssetPreview, propsAreEqual)
