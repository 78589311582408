import { Switch } from '@headlessui/react'
import { useQueryClient } from '@tanstack/react-query'
import { useWallet } from '@txnlab/use-wallet-react'
import { HiLockClosed, HiLockOpen } from 'react-icons/hi'
import IconBadge from 'components/DetailView/Vault/IconBadge'
import { usePostVaultOptInLock } from 'api/hooks/usePostVaultOptInLock'
import { useNameUpdate } from 'api/hooks/useName'
import useErrorToast from 'hooks/useErrorToast'
import { isVaultUnlocked } from 'helpers/vaults'
import { classNames } from 'helpers/utilities'
import type { NfdRecord } from 'api/api-client'

interface VaultLockToggleProps {
  nfd: NfdRecord
  showLabel?: boolean
}

export default function VaultLockToggle({ nfd, showLabel = false }: VaultLockToggleProps) {
  const isUnlocked = isVaultUnlocked(nfd)

  const { activeAddress } = useWallet()
  const queryClient = useQueryClient()
  const handleError = useErrorToast()

  const optimisticUpdate = useNameUpdate()

  const { mutateAsync: updateVaultOptInLock, isLoading } = usePostVaultOptInLock({
    onMutate(params) {
      if (!params) return

      const prevNfd: NfdRecord | undefined = queryClient.getQueryData(['name', params.name])

      if (!prevNfd) return

      const newNfd: NfdRecord = {
        ...prevNfd,
        properties: {
          ...prevNfd.properties,
          internal: {
            ...prevNfd.properties?.internal,
            vaultOptInLocked: params.body.lock ? '1' : '0'
          }
        }
      }

      optimisticUpdate(newNfd)

      return { prevNfd, newNfd }
    },
    onError: (error, params, context) => optimisticUpdate(context?.prevNfd),
    onSuccess: (data, params, context) => optimisticUpdate(context?.newNfd)
  })

  const handleUpdateVaultOptInLock = async () => {
    if (isLoading) return

    try {
      if (!activeAddress) {
        throw new Error('Wallet not connected')
      }

      await updateVaultOptInLock({
        name: nfd.name,
        body: {
          lock: isUnlocked,
          sender: activeAddress
        }
      })
    } catch (e) {
      handleError(e)
    }
  }

  const renderToggle = () => {
    return (
      <Switch
        checked={isUnlocked}
        onChange={handleUpdateVaultOptInLock}
        className={classNames(
          isUnlocked ? 'bg-brand-600' : 'bg-gray-200 dark:bg-gray-700',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-brand-600 focus:ring-offset-2 dark:ring-offset-gray-850',
          isLoading ? 'focus:ring-transparent opacity-50 cursor-progress' : ''
        )}
      >
        <span className="sr-only">Unlock vault</span>
        <span
          aria-hidden="true"
          className={classNames(
            isUnlocked
              ? 'bg-white dark:bg-gray-200 translate-x-5'
              : 'bg-white dark:bg-gray-400 translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full shadow transform ring-0 transition ease-in-out duration-200'
          )}
        />
      </Switch>
    )
  }

  const badge = isUnlocked
    ? { icon: HiLockOpen, label: isLoading ? <>Unlocking&hellip;</> : 'Unlocked' }
    : { icon: HiLockClosed, label: isLoading ? <>Locking&hellip;</> : 'Locked' }

  return showLabel ? (
    <Switch.Group
      as="div"
      className={classNames(
        'flex items-center space-x-3 py-2 pr-3 xs:px-4',
        isLoading ? 'cursor-progress' : ''
      )}
    >
      {renderToggle()}
      <IconBadge icon={badge.icon} label={badge.label} isLoading={isLoading} />
    </Switch.Group>
  ) : (
    renderToggle()
  )
}
