import { useWallet } from '@txnlab/use-wallet-react'
import * as React from 'react'
import useName from '@/api/hooks/useName'
import { NfdRecord } from '@/api/api-client'

type PollingStatus = 'idle' | 'polling' | 'success' | 'error'

interface UseNfdPollingResult {
  startPolling: () => void
  status: PollingStatus
  error: Error | null
}

export function useNfdPolling(
  name: string,
  condition: (nfd: NfdRecord, activeAddress?: string | null) => boolean,
  eventName: string
): UseNfdPollingResult {
  const [status, setStatus] = React.useState<PollingStatus>('idle')
  const [error, setError] = React.useState<Error | null>(null)

  const { activeAddress } = useWallet()

  const { data: nfd } = useName({
    name,
    params: { view: 'full', poll: true },
    options: {
      enabled: status === 'polling',
      refetchInterval: 2000,
      retry: 3,
      onError: (error) => {
        console.log('polling error', error)
        setStatus('error')
        setError(error as Error)
      }
    }
  })

  React.useEffect(() => {
    if (status === 'polling') {
      console.log('polling', eventName)
      if (nfd && condition(nfd, activeAddress)) {
        console.log('condition met, dispatching event', eventName)
        setStatus('success')
        // Emit custom event
        window.dispatchEvent(new CustomEvent(eventName, { detail: { name: nfd.name } }))
      }
    }
  }, [activeAddress, nfd, status, condition, eventName])

  const startPolling = () => {
    console.log('startPolling', eventName)
    setStatus('polling')
  }

  return { startPolling, status, error }
}
