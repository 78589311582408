import { Menu, Transition } from '@headlessui/react'
import { QueryKey } from '@tanstack/react-query'
import dynamic from 'next/dynamic'
import { Fragment } from 'react'
import { HiDotsVertical } from 'react-icons/hi'
import AssetMedia from 'components/AssetMedia'
import Button from 'components/Button'
import ExplorerIcon from 'components/ExplorerIcon'
import VerifiedBadge from 'components/VerifiedBadge'
import { IconPeraExplorer } from '../Gallery.icons'
import useActionMenu from './ActionMenu.hooks'
import galleryImageLoader from 'helpers/galleryImageLoader'
import { ICONS } from './ActionMenu.constants'
import type { Asset } from '../Gallery.types'
import type { NfdRecord } from 'api/api-client'

const Modal = dynamic(() => import('components/Modal'))
const SendModal = dynamic(() => import('components/SendModal'))

interface ActionMenuProps {
  nfd: NfdRecord
  asset: Asset
  queryKey: QueryKey
  pageIndex: number
  index: number
}

export default function ActionMenu({ nfd, asset, queryKey, pageIndex, index }: ActionMenuProps) {
  const {
    isModalOpen,
    field,
    isUpdating,
    setIsModalOpen,
    handleClickSetField,
    handleClickVerify,
    handleClickGoBack,
    reset,
    selectedExplorer,
    lookupByAssetId,
    canMoveToVault,
    canSendAsset,
    sender,
    receiver,
    receiverType,
    isSendModalOpen,
    setIsSendModalOpen,
    handleClickMoveToVault,
    canMoveToDepositAccount,
    handleClickMoveToDepositAccount,
    handleSendOptimisticUpdate,
    handleResetReceiver
  } = useActionMenu({ nfd, asset, queryKey, pageIndex, index })

  const renderModalContent = () => {
    return (
      <div>
        <p className="text-sm text-gray-500 break-words mb-6">
          Prove you own your <span className="font-semibold text-gray-600">{field}</span> by
          verifying it!
        </p>
        <div className="flex">
          <div className="relative aspect-square w-24 h-24 sm:w-full sm:h-full shadow-lg rounded-lg overflow-hidden">
            <AssetMedia
              src={asset.imageUrl}
              alt={asset.asaID.toString()}
              className="object-cover w-full h-full"
              loader={galleryImageLoader}
              width={100}
              height={100}
              options={{ optimizeGifs: false }}
            />
            <div className="absolute inset-0 rounded-lg overflow-hidden">
              <div className="block absolute bottom-0 right-0 -mr-12 -mb-12">
                <div>
                  <VerifiedBadge />
                </div>
              </div>
            </div>
          </div>
          <div className="text-sm ml-4 text-left space-y-2 flex flex-col justify-center w-full">
            <div>
              <p className="font-medium text-gray-700">Name</p>
              <p className="text-gray-500 truncate">{asset.name}</p>
            </div>
            <div>
              <p className="font-medium text-gray-700">Asset ID</p>
              <p className="text-gray-500 truncate">{asset.asaID}</p>
            </div>
          </div>
        </div>
        <div className="mt-8 capitalize space-x-4">
          <Button variant="default" onClick={handleClickGoBack}>
            <p className="capitalize">Go back</p>
          </Button>
          <Button variant="primary" onClick={handleClickVerify} disabled={isUpdating}>
            <p className="capitalize">{isUpdating ? `Verifying ${field}...` : `Verify ${field}`}</p>
          </Button>
        </div>
        <div className="mt-6 capitalize"></div>
      </div>
    )
  }

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <div className="flex items-center">
          <Menu.Button className="bg-white rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-brand-500 dark:bg-transparent dark:text-gray-500 dark:hover:text-gray-400 dark:focus:ring-offset-gray-900">
            <span className="sr-only">Open menu</span>
            <HiDotsVertical className="h-6 w-6" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="z-20 origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none dark:bg-gray-800 dark:text-gray-400 dark:ring-gray-800/40 dark:divide-gray-750">
            <div className="py-1">
              <Menu.Item>
                <a
                  href={lookupByAssetId(asset.asaID)}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="group inline-flex items-center w-full px-4 py-2 text-left text-sm font-normal text-gray-600 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-900/75 dark:hover:text-gray-200"
                >
                  <ExplorerIcon
                    className="w-4 h-4 mr-3 text-gray-400 group-hover:text-gray-600 dark:text-gray-500 dark:group-hover:text-gray-300"
                    explorer={selectedExplorer}
                  />{' '}
                  {selectedExplorer}
                </a>
              </Menu.Item>
              <Menu.Item>
                <a
                  href={`https://explorer.perawallet.app/asset/${asset.asaID}`}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="inline-flex items-center w-full px-4 py-2 text-left text-sm font-normal text-gray-600 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-900/75 dark:hover:text-gray-200"
                >
                  <IconPeraExplorer className="w-4 h-4 mr-3 text-gray-400 group-hover:text-gray-600 dark:text-gray-500 dark:group-hover:text-gray-300" />{' '}
                  Pera Explorer
                </a>
              </Menu.Item>
            </div>
            <div className="py-1">
              <Menu.Item>
                <button
                  onClick={() => handleClickSetField('avatar')}
                  className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-300 dark:hover:bg-gray-900/75 dark:hover:text-gray-100"
                >
                  Set as Avatar
                </button>
              </Menu.Item>
              <Menu.Item>
                <button
                  onClick={() => handleClickSetField('banner')}
                  className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-300 dark:hover:bg-gray-900/75 dark:hover:text-gray-100"
                >
                  Set as Banner
                </button>
              </Menu.Item>
            </div>

            {canMoveToVault && (
              <div className="py-1">
                <Menu.Item>
                  <button
                    onClick={handleClickMoveToVault}
                    className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-300 dark:hover:bg-gray-900/75 dark:hover:text-gray-100"
                  >
                    Move to Vault
                  </button>
                </Menu.Item>
              </div>
            )}

            {canMoveToDepositAccount && (
              <div className="py-1">
                <Menu.Item>
                  <button
                    onClick={handleClickMoveToDepositAccount}
                    className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-300 dark:hover:bg-gray-900/75 dark:hover:text-gray-100"
                  >
                    Move to Deposit Account
                  </button>
                </Menu.Item>
              </div>
            )}

            {canSendAsset && !!sender && (
              <div className="py-1">
                <Menu.Item>
                  <button
                    className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-300 dark:hover:bg-gray-900/75 dark:hover:text-gray-100"
                    onClick={() => setIsSendModalOpen(true)}
                  >
                    Send Asset
                  </button>
                </Menu.Item>
              </div>
            )}
          </Menu.Items>
        </Transition>
      </Menu>

      <Modal
        onClose={reset}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        icon={field ? ICONS[field] : ICONS.default}
        title={`Update ${field ? field : ''}`}
      >
        <div className="mt-6">{renderModalContent()}</div>
      </Modal>

      {canSendAsset && !!sender && (
        <SendModal
          key={receiver || ''}
          isOpen={isSendModalOpen}
          setIsOpen={setIsSendModalOpen}
          asset={asset.asaID}
          sender={sender}
          receiver={receiver}
          receiverType={receiverType}
          showReceiver={!!receiver}
          onSuccess={handleSendOptimisticUpdate}
          onClose={handleResetReceiver}
        />
      )}
    </>
  )
}
