import axios, { AxiosRequestConfig } from 'axios'
import type { AlgoXNFTListing } from './types'
import { ALGOXNFT_BASE_URL, ALGOXNFT_API_BASE_URL, REFERRAL_ID } from './constants'
import type { Listing } from 'api/hooks/useNFTListings'

export const mapList = (list: AlgoXNFTListing[], isAuction?: boolean): Listing[] => {
  return list.map((listing) => {
    const mapped: Listing = {
      name: listing.name,
      assetId: listing.asset_id,
      creatorAddress: listing.creator_wallet_address,
      price: Number(listing.price),
      imageUrl: listing.image_url,
      marketplace: 'AlgoXNFT',
      marketplaceLink: `${ALGOXNFT_BASE_URL}/asset/${listing.asset_id}?r=${REFERRAL_ID}`
    }

    if (isAuction) {
      mapped.auction = {
        startingBid: listing.starting_bid as number,
        winningBid: listing.current_winner_bid as number,
        endingTime: listing.ending_time as string
      }
    }

    return mapped
  })
}

export const getAlgoXNftAuctionList = async (
  address: string,
  ssr?: boolean,
  options?: AxiosRequestConfig
) => {
  const { data } = await axios.get<AlgoXNFTListing[]>(
    `${ALGOXNFT_API_BASE_URL}/v1/listings?seller_address=${address}&status=live`,
    {
      ...(ssr && { headers: { origin: process.env.NEXT_PUBLIC_BASE_URL as string } }),
      ...options
    }
  )

  if (!data || !data.length) {
    throw new Error('Failed to fetch AlgoXNFT auction list')
  }

  return mapList(data, true)
}

export const getAlgoXNftBuyNowList = async (
  address: string,
  ssr?: boolean,
  options?: AxiosRequestConfig
) => {
  const { data } = await axios.get<AlgoXNFTListing[]>(
    `${ALGOXNFT_API_BASE_URL}/v1/buy-it-now-listings?seller_address=${address}&show_zero_quantity=false`,
    {
      ...(ssr && { headers: { origin: process.env.NEXT_PUBLIC_BASE_URL as string } }),
      ...options
    }
  )

  if (!data) {
    throw new Error('There are no AlgoXNFT listings for this address')
  }

  return mapList(data)
}
