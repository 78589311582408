import Creations from './Creations'
import Filters from './Filters'
import ForSale from './ForSale'
import Owned from './Owned'
import StickyNav from './StickyNav'
import SubNavigation from './SubNavigation'
import Vault from './Vault'
import useAssets from './Assets.hooks'
import { Context, type PanelProps } from 'components/DetailView/DetailView.types'

export default function Assets({ nfd, context }: PanelProps) {
  const {
    activeTab,
    searchQuery,
    setSearchQuery,
    allAccounts,
    accounts,
    setAccounts,
    setAccountsAsync,
    typeFilter,
    setTypeFilter,
    isReady,
    viewSetting,
    setViewSetting
  } = useAssets(nfd)

  const renderPage = () => {
    switch (activeTab) {
      case 'vault':
        return (
          <Vault
            nfd={nfd}
            searchQuery={searchQuery}
            typeFilter={typeFilter}
            viewSetting={viewSetting}
            isOwner={context === Context.Manage}
          />
        )

      case 'owned':
        return (
          <Owned
            nfd={nfd}
            searchQuery={searchQuery}
            accounts={accounts}
            typeFilter={typeFilter}
            viewSetting={viewSetting}
          />
        )

      case 'for-sale':
        return (
          <ForSale
            nfd={nfd}
            searchQuery={searchQuery}
            accounts={accounts}
            typeFilter={typeFilter}
            viewSetting={viewSetting}
          />
        )

      case 'creations':
        return (
          <Creations
            nfd={nfd}
            searchQuery={searchQuery}
            accounts={accounts}
            typeFilter={typeFilter}
            viewSetting={viewSetting}
          />
        )

      default:
        return null
    }
  }

  const renderContent = () => {
    if (!isReady) return null

    return (
      <>
        <div className="2xl:sticky 2xl:top-[120px] 2xl:z-20 relative bg-white border-b border-gray-200 dark:bg-gray-900 dark:border-gray-800">
          <div className="2xl:flex 2xl:items-center 2xl:justify-between">
            <div className="border-b border-gray-200 dark:border-gray-800 2xl:border-0">
              <SubNavigation nfd={nfd} activeTab={activeTab} />
            </div>
            <Filters
              nfd={nfd}
              activeTab={activeTab}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              allAccounts={allAccounts}
              accounts={accounts}
              setAccounts={setAccounts}
              setAccountsAsync={setAccountsAsync}
              typeFilter={typeFilter}
              setTypeFilter={setTypeFilter}
              viewSetting={viewSetting}
              setViewSetting={setViewSetting}
            />
          </div>
          <StickyNav top={120} activeTab={activeTab} className="2xl:hidden" />
        </div>

        <div key={activeTab}>{renderPage()}</div>
      </>
    )
  }

  return (
    <div className="relative flex-1 flex flex-col">
      <div className="flex-1 bg-gray-50 pb-96 dark:bg-gray-950">{renderContent()}</div>
    </div>
  )
}
