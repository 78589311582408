import AlgoPrice from 'components/AlgoPrice'
import { formatAssetBalance } from 'helpers/utilities'
import type { NftListing } from 'types/listings'

export const showTextAvatar = (asset: NftListing) => {
  return (
    asset.unitName?.toLocaleLowerCase() === 'nfd' &&
    asset.imageUrl.includes('nfd-image-placeholder')
  )
}

export const renderPrice = (asset: NftListing) => {
  const assetPrice = asset.auction
    ? asset.auction.winningBid || asset.auction.startingBid
    : asset.price

  if (!assetPrice) return null

  if (asset.priceAsset) {
    return (
      <span className="font-medium text-base">
        {formatAssetBalance(
          assetPrice,
          asset.priceAsset.decimals,
          true,
          true,
          12 - asset.priceAsset.unitName.length
        )}{' '}
        {asset.priceAsset.unitName}
      </span>
    )
  }

  return <AlgoPrice price={assetPrice} className="font-medium text-base" />
}
