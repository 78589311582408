import { useEffect, useMemo, useState } from 'react'
import useGetActivity from 'api/hooks/useGetActivity'
import { getCarryCost } from './History.utils'
import { convertMicroalgosToAlgos } from 'src/helpers/utilities'
import { HistoryData, HistoryType } from './History.types'
import type { NFDActivity, NfdRecord } from 'types/api'

export default function useHistory(nfd: NfdRecord) {
  const [history, setHistory] = useState<HistoryData[][]>([])

  const {
    data: activityData,
    error: _error,
    isInitialLoading: _isLoading
  } = useGetActivity({
    params: {
      name: [nfd.name],
      limit: 50
    }
  })

  const isAuction = nfd.saleType === 'auction'

  const dep = JSON.stringify(activityData)
  useEffect(() => {
    if (!activityData) return

    for (const activity of activityData) {
      updateHistory(activity).then((series) => {
        if (series) {
          setHistory((prevState) => prevState.concat([series]))
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dep])

  const getType = (key: string, value: string) => {
    if (value === '') {
      return HistoryType.Remove
    }

    if (key.startsWith('u')) {
      return HistoryType.Update
    }

    if (key.startsWith('v')) {
      return HistoryType.Verify
    }

    return HistoryType.Internal
  }

  const getPrice = (price: string, addCarryCost?: boolean) => {
    let basePrice = Number(price)

    if (addCarryCost) {
      basePrice += getCarryCost(nfd)
    }

    return convertMicroalgosToAlgos(basePrice)
  }

  const updateHistory = async (activity: NFDActivity) => {
    if (!activity || !activity.changes) {
      return
    }

    const series: HistoryData[] = []
    const { changes } = activity
    const filteredChanges = Object.entries(changes).filter(([key]) => !key.startsWith('i'))

    // MINTED BUY NOW
    if (changes['i:minting'] === 'registry') {
      series.push({
        type: HistoryType.Claim,
        text: 'by',
        isMain: true,
        date: activity.timeChanged as string,
        address: changes['i:owner']
      })
      series.push({
        type: HistoryType.Mint,
        text: 'for',
        price: getPrice(changes['i:sellamt'], false),
        isMain: true,
        date: activity.timeChanged as string
      })
    } else if (changes['i:minting'] === '1' && changes['i:saleType'] === 'buyItNow') {
      series.push({
        type: HistoryType.Mint,
        text: '',
        isMain: true,
        date: activity.timeChanged as string
      })
    } else if (changes['i:minting'] === '1' && changes['i:saleType'] === 'auction') {
      series.push({
        type: HistoryType.Auction,
        text: '',
        isMain: true,
        date: activity.timeChanged as string
      })
      // USED DEFINED PROPERTIES ONLY
    } else if (
      filteredChanges.length &&
      filteredChanges.every(([key]) => key.startsWith('u')) &&
      changes['i:sellamt'] === undefined
    ) {
      const numOfProperties = filteredChanges.length

      series.push({
        type: HistoryType.Update,
        text: numOfProperties > 1 ? `${numOfProperties} properties` : '1 property',
        isMain: true,
        date: activity.timeChanged as string
      })

      filteredChanges.forEach(([key, value]) => {
        const formattedKey = key.startsWith('v:caAlgo') ? 'Address' : key.slice(2)

        series.push({
          type: getType(key, value),
          text: `${key}: ${value}`,
          key: formattedKey,
          value,
          isMain: false,
          date: activity.timeChanged as string,
          price: ''
        })
      })
      // VERIFIED PROPERTIES ONLY
    } else if (
      filteredChanges.length &&
      filteredChanges.every(([key]) => key.startsWith('v')) &&
      changes['i:sellamt'] === undefined
    ) {
      // If they updated their address
      if (filteredChanges.every(([key]) => key.startsWith('v:caAlgo'))) {
        series.push({
          type: HistoryType.Verify,
          text: 'wallet addresses',
          isMain: true,
          date: activity.timeChanged as string
        })
      } else {
        const numOfProperties = filteredChanges.filter(([key]) => !key.startsWith('i')).length
        series.push({
          type: HistoryType.Verify,
          text: numOfProperties > 1 ? `${numOfProperties} properties` : '1 property',
          isMain: true,
          date: activity.timeChanged as string
        })
      }

      filteredChanges.forEach(([key, value]) => {
        const isAddress = key.startsWith('v:caAlgo')

        if (isAddress) {
          value.split(',').forEach((address, index) => {
            series.push({
              type: getType(key, value),
              text: `${key}: ${value}`,
              key: index === 0 ? 'Deposit Address' : 'Linked Address',
              value: address,
              isMain: false,
              date: activity.timeChanged as string,
              price: ''
            })
          })
        } else {
          series.push({
            type: getType(key, value),
            text: `${key}: ${value}`,
            key: key.slice(2),
            value,
            isMain: false,
            date: activity.timeChanged as string,
            price: ''
          })
        }
      })
      // MIXED PROPERTIES
    } else if (
      filteredChanges.length &&
      filteredChanges.some(([key]) => key.startsWith('v') || key.startsWith('u')) &&
      changes['i:sellamt'] === undefined
    ) {
      const numOfProperties = filteredChanges.length

      series.push({
        type: HistoryType.Many,
        text: `${numOfProperties} user defined & verified properties`,
        isMain: true,
        date: activity.timeChanged as string
      })

      filteredChanges.forEach(([key, value]) => {
        const formattedKey = key.startsWith('v:caAlgo') ? 'Address' : key.slice(2)

        series.push({
          type: getType(key, value),
          text: `${key}: ${value}`,
          key: formattedKey,
          value,
          isMain: false,
          date: activity.timeChanged as string,
          price: ''
        })
      })
      // INITIAL CLAIM
    } else if (
      changes['i:minting'] === '' &&
      changes['i:highestSoldAmt'] !== undefined &&
      changes['i:highestSoldAmt'] !== ''
    ) {
      const address = changes['i:owner']
      const text = `by`

      series.push({
        type: HistoryType.Claim,
        text,
        isMain: true,
        date: activity.timeChanged as string,
        price: getPrice(changes['i:highestSoldAmt'], !isAuction),
        address
      })
      // CLAIMED
    } else if (
      changes['i:minting'] === undefined &&
      changes['i:timePurchased'] !== undefined &&
      changes['i:timePurchased'] !== '' &&
      changes['i:owner'] !== undefined &&
      changes['i:owner'] !== ''
    ) {
      const address = changes['i:owner']
      const text = `by`

      series.push({
        type: HistoryType.Claim,
        text,
        isMain: true,
        date: activity.timeChanged as string,
        address
      })
      // AUCTION
    } else if (changes['i:saleType'] === 'auction') {
      series.push({
        type: HistoryType.Auction,
        text: '',
        isMain: true,
        date: activity.timeChanged as string
      })
      // PUT FOR SALE
    } else if (
      changes['i:minting'] === undefined &&
      changes['i:sellamt'] !== undefined &&
      changes['i:sellamt'] !== ''
    ) {
      series.push({
        type: HistoryType.Sell,
        text: '',
        isMain: true,
        date: activity.timeChanged as string,
        price: getPrice(changes['i:sellamt'], false)
      })
    }
    // CANCELLED SALE
    else if (
      changes['i:owner'] === undefined &&
      changes['i:sellamt'] !== undefined &&
      changes['i:sellamt'] === ''
    ) {
      series.push({
        type: HistoryType.CancelSell,
        text: '',
        isMain: true,
        date: activity.timeChanged as string
      })
    }
    // CONTRACT UPGRADE
    else if (changes['i:ver'] !== undefined) {
      series.push({
        type: HistoryType.Upgrade,
        text: 'to version ' + changes['i:ver'],
        isMain: true,
        date: activity.timeChanged as string
      })
    }

    return series
  }

  const historySorted = useMemo(() => {
    return history.sort(function (a, b) {
      if (!history || !a[0] || !b[0]) return 0

      const dateA0 = new Date(a[0].date).valueOf()
      const dateB0 = new Date(b[0].date).valueOf()

      if (dateA0 == dateB0) {
        if (!history || !a[1] || !b[1]) return 0

        const dateA1 = new Date(a[1].date).valueOf()
        const dateB1 = new Date(b[1].date).valueOf()

        return dateA1 - dateB1
      }
      return dateB0 - dateA0
    })
  }, [history])

  return historySorted
}
