import dynamic from 'next/dynamic'
import { Fragment } from 'react'
import { AiFillBank } from 'react-icons/ai'
import { BiCopy } from 'react-icons/bi'
import { HiLockClosed, HiLockOpen, HiOutlineSearch, HiOutlineX } from 'react-icons/hi'
import { HiArrowUpRight } from 'react-icons/hi2'
import Alert from 'components/Alert'
import Button from 'components/Button'
import AssetThumbnail from './AssetThumbnail'
import LoadingDots from 'components/LoadingDots'
import IconBadge from './IconBadge'
import VaultLockToggle from './VaultLockToggle'
import useVault from './Vault.hooks'
import { copyToClipboard } from 'helpers/copyToClipboard'
import { isVaultUnlocked } from 'helpers/vaults'
import { formatAssetBalance, truncateAddress } from 'helpers/utilities'
import { Context, PanelProps } from '../DetailView.types'

const SendModal = dynamic(() => import('components/SendModal'))

export default function Vault({ nfd, context }: PanelProps) {
  const {
    data,
    isLoading,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    allAssets,
    vaultAddress,
    searchRef,
    searchText,
    handleKeyDown,
    handleSearchTextChange,
    resetSearchText,
    handleInvalidateQuery,
    isEven
  } = useVault(nfd)

  const renderAssets = () => {
    return data?.pages.map((page, idx) => (
      <Fragment key={idx}>
        {page.assets.map((asset, i) => (
          <tr key={i} className={isEven(asset.id) ? undefined : 'bg-gray-50 dark:bg-gray-900/50'}>
            <td className="w-full max-w-0 whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6 dark:text-gray-100">
              <div className="flex items-center -my-1.5">
                <AssetThumbnail
                  key={asset.id}
                  asset={asset}
                  className="relative h-12 w-12 mr-4 flex-none rounded-full overflow-hidden bg-black/5 dark:bg-white/5"
                />
                <div className="min-w-0">
                  <p className="truncate">{asset.name}</p>
                  <dl className="font-normal md:hidden">
                    <dt className="sr-only">Quantity</dt>
                    <dd className="truncate font-mono text-gray-700 dark:text-gray-400">
                      <span>{formatAssetBalance(asset.amount, asset.decimals)}</span>
                      <span className="ml-2 text-gray-500">{asset.unitName}</span>
                    </dd>
                  </dl>
                </div>
              </div>
            </td>
            <td className="hidden whitespace-nowrap px-3 py-4 text-sm font-mono text-gray-500 md:table-cell dark:text-gray-400">
              {asset.unitName}
            </td>
            <td className="hidden whitespace-nowrap px-3 py-4 text-sm font-mono text-gray-500 md:table-cell dark:text-gray-400">
              {asset.id > 0 ? (
                asset.id
              ) : (
                <span className="text-gray-400 dark:text-gray-500">--</span>
              )}
            </td>
            <td className="hidden whitespace-nowrap px-3 py-4 text-sm font-mono text-right md:table-cell">
              <span className="font-medium">
                {formatAssetBalance(asset.amount, asset.decimals)}
              </span>
            </td>
            <td className="whitespace-nowrap py-4 pl-3 pr-4 w-16 text-center text-sm font-medium sm:pr-6">
              {context === Context.Manage ? (
                <SendModal asset={asset} sender={nfd} onSuccess={handleInvalidateQuery}>
                  <Button size="xs" className="xl:ml-4">
                    <HiArrowUpRight className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                    Send
                  </Button>
                </SendModal>
              ) : (
                <>&nbsp;</>
              )}
            </td>
          </tr>
        ))}
      </Fragment>
    ))
  }

  const renderAddress = () => {
    const addr = truncateAddress(vaultAddress, { array: true })

    return (
      <button
        type="button"
        onClick={copyToClipboard}
        data-clipboard-text={vaultAddress}
        className="hidden xs:inline-flex items-center px-1 py-0.5 sm:-mx-1 whitespace-nowrap rounded text-base text-gray-700 font-mono group hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-brand-500 dark:text-gray-300 dark:hover:text-gray-100"
      >
        <BiCopy
          className="md:hidden flex-shrink-0 ml-1 sm:ml-0 sm:mr-2 h-6 w-6 text-gray-600 group-hover:text-gray-800 dark:text-gray-500 dark:group-hover:text-gray-400"
          aria-hidden="true"
        />
        <AiFillBank
          className="hidden flex-shrink-0 md:inline-flex md:mr-2 h-6 w-6 text-gray-600 group-hover:text-gray-800 dark:text-gray-500 dark:group-hover:text-gray-400"
          aria-hidden="true"
        />
        <span className="hidden md:inline">
          {addr[0]}&hellip;{addr[1]}
        </span>
      </button>
    )
  }

  const renderLockBadge = () => {
    if (context === Context.Manage) {
      return (
        <div className="pl-1">
          <VaultLockToggle nfd={nfd} showLabel />
        </div>
      )
    }

    const badge = isVaultUnlocked(nfd)
      ? { icon: HiLockOpen, label: 'Unlocked' }
      : { icon: HiLockClosed, label: 'Locked' }

    return (
      <div className="flex items-center py-2 px-4">
        <IconBadge icon={badge.icon} label={badge.label} />
      </div>
    )
  }

  const renderTable = () => {
    if (isLoading) {
      return (
        <div className="p-12 text-center text-gray-500">
          <LoadingDots className="my-1.5" />
        </div>
      )
    }

    if (error) {
      return (
        <div className="p-8">
          <Alert type="error" title={`Error fetching vault assets`} error={error} />
        </div>
      )
    }

    if (!allAssets.length) {
      return (
        <div className="p-8">
          <Alert type="info" title="No assets found matching search text" />
        </div>
      )
    }

    return (
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full border-separate" style={{ borderSpacing: 0 }}>
            <thead className="relative bg-gray-100 dark:bg-gray-900">
              <tr>
                <th
                  scope="col"
                  className="border-gray-300 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 bg-gray-100 sm:pl-6 dark:text-gray-500 dark:border-transparent dark:bg-gray-900"
                >
                  Asset
                </th>
                <th
                  scope="col"
                  className="hidden border-gray-300 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-gray-100 md:table-cell dark:text-gray-500 dark:border-transparent dark:bg-gray-900"
                >
                  Unit Name
                </th>
                <th
                  scope="col"
                  className="hidden border-gray-300 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-gray-100 md:table-cell dark:text-gray-500 dark:border-transparent dark:bg-gray-900"
                >
                  Asset ID
                </th>
                <th
                  scope="col"
                  className="hidden border-gray-300 px-3 py-3.5 text-right text-sm font-semibold text-gray-900 bg-gray-100 md:table-cell dark:text-gray-500 dark:border-transparent dark:bg-gray-900"
                >
                  Quantity
                </th>
                <th
                  scope="col"
                  className="border-gray-300 pl-3 pr-4 sm:pr-6 py-3.5 text-center text-sm font-semibold text-gray-900 bg-gray-100 xl:text-left dark:text-gray-500 dark:border-transparent dark:bg-gray-900"
                >
                  <span className="sr-only">Actions</span>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-gray-850">{renderAssets()}</tbody>
          </table>
        </div>
      </div>
    )
  }

  return (
    <div className="flex-1 flex flex-col">
      <div className="bg-gray-50 flex-1 sm:pt-2 pb-8 5xl:pt-4 dark:bg-gray-950/40">
        <div className="max-w-5xl mx-auto sm:p-4 sm:px-6 lg:px-8 5xl:px-10">
          <div className="bg-white shadow sm:rounded-lg overflow-hidden dark:bg-gray-850 dark:sm:highlight">
            <div className="flex justify-between items-center px-4 py-5 sm:px-6 xl:space-x-2">
              <div className="flex items-center xs:space-x-3 md:space-x-5 xs:divide-x xs:divide-gray-200 dark:xs:divide-gray-750">
                {renderAddress()}
                {renderLockBadge()}
              </div>
              <div className="flex items-center justify-between w-full sm:w-auto">
                <div className="w-full sm:w-80 relative rounded-md shadow-sm text-gray-400 focus-within:text-gray-600 dark:text-gray-600 dark:focus-within:text-gray-400">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <HiOutlineSearch className="h-5 w-5" aria-hidden="true" />
                  </div>
                  <label htmlFor="vault-search" className="sr-only">
                    Search assets
                  </label>
                  <input
                    ref={searchRef}
                    id="vault-search"
                    name="vault-search"
                    type="text"
                    value={searchText}
                    onChange={handleSearchTextChange}
                    onKeyDown={handleKeyDown}
                    autoComplete="off"
                    autoCorrect="off"
                    className="block w-full rounded-md border-gray-300 px-10 focus:border-brand-500 focus:ring-brand-500 sm:text-sm dark:bg-gray-750/75 dark:border-transparent dark:text-gray-100 dark:focus:border-brand-500 dark:placeholder-gray-500 dark:caret-gray-400"
                    placeholder="Search assets"
                    maxLength={27}
                  />
                  {searchText.length > 0 && (
                    <div className="absolute inset-y-0 right-0 flex items-center">
                      <button
                        type="button"
                        className="inline-flex items-center mr-2 border border-transparent rounded-full text-gray-400 bg-white hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 focus:text-gray-600 dark:bg-transparent dark:text-gray-500 dark:hover:text-gray-400 dark:focus:ring-offset-gray-800"
                        onClick={resetSearchText}
                      >
                        <HiOutlineX className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flow-root border-t border-gray-200 dark:border-gray-750/75">
              {renderTable()}
            </div>
          </div>
          <div className="flex items-center justify-center h-20">
            {isFetchingNextPage ? (
              <LoadingDots />
            ) : hasNextPage ? (
              <Button size="lg" onClick={() => fetchNextPage()}>
                Load more
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}
