import { MoreTabViews, PanelProps, Sections } from './DetailView.types'
import History from './History'
import Offers from './Offers'
import Sales from './Sales'

type MorePanelProps = PanelProps & {
  activeTab: MoreTabViews
}

export default function MorePanel({ nfd, context, activeTab }: MorePanelProps) {
  switch (activeTab) {
    case Sections.Sales:
      return <Sales nfd={nfd} context={context} />
    case Sections.Offers:
      return <Offers nfd={nfd} context={context} />
    case Sections.History:
      return <History nfd={nfd} context={context} />
    default:
      return null
  }
}
