import React from 'react'
import UserThumbnail from 'components/UserThumbnail'
import { Context } from 'components/DetailView/DetailView.types'
import ActionMenu from '../ActionMenu'
import FooterMenu from '../FooterMenu'
import AssetPreview from '../AssetPreview'
import { getNfdHasDefaultAvatar } from '../Gallery.utils'
import type { QueryKey } from '@tanstack/react-query'
import type { NfdRecord } from 'api/api-client'
import type { Asset } from '../Gallery.types'

interface AssetOwnedProps {
  asset: Asset
  nfd: NfdRecord
  context: Context
  onImageClick: () => void
  queryKey: QueryKey
  pageIndex: number
  index: number
}

function AssetOwned({
  asset,
  nfd,
  context,
  onImageClick,
  queryKey,
  pageIndex,
  index
}: AssetOwnedProps) {
  const isInVault = asset.foundIn === nfd.nfdAccount

  const renderMenu = () => {
    if (context == Context.Manage && asset.unitName?.toLowerCase() !== 'nfd') {
      return (
        <ActionMenu
          nfd={nfd}
          asset={asset}
          queryKey={queryKey}
          pageIndex={pageIndex}
          index={index}
        />
      )
    }

    return <FooterMenu asset={asset} />
  }

  return (
    <div>
      <AssetPreview
        src={asset.imageUrl}
        assetId={asset.asaID.toString()}
        onClick={onImageClick}
        nfdName={asset.name && getNfdHasDefaultAvatar(asset) ? asset.name : undefined}
        isInVault={isInVault}
      />
      <div className="mb-2">
        <div className="flex-1 flex items-center space-x-1 mt-2 min-w-0">
          <h3 className="text-md font-semibold text-gray-900 truncate dark:text-gray-300">
            {asset.name}
          </h3>
        </div>
        <div className="mt-1.5 flex items-center justify-between text-sm">
          <div className="w-4/5">
            <UserThumbnail
              address={asset.creator}
              size="sm"
              className="text-gray-400 truncate"
              forceAddressFallback={asset.unitName === 'NFD'}
              fallbackClassName="text-gray-400 truncate dark:text-gray-400/80"
              staleTime={Infinity}
            />
          </div>
          {renderMenu()}
        </div>
      </div>
    </div>
  )
}

function propsAreEqual(prevProps: AssetOwnedProps, nextProps: AssetOwnedProps) {
  const { asset, nfd, context } = nextProps

  return (
    prevProps.asset.asaID === asset.asaID &&
    prevProps.nfd.name === nfd.name &&
    prevProps.context === context &&
    prevProps.asset.foundIn === asset.foundIn
  )
}

export default React.memo(AssetOwned, propsAreEqual)
