import { getByQuery } from 'api/hooks/v2/useSearch'
import { AxiosRequestConfig } from 'axios'
import type { NfdomainsListing } from './types'
import { NFDOMAINS_BASE_URL } from './constants'
import type { Listing } from 'api/hooks/useNFTListings'
import type { Marketplace } from 'types/listings'

export const mapList = (list: NfdomainsListing[]): Listing[] => {
  return list
    .filter((listing) => listing.state === 'forSale' && !listing.reservedFor)
    .map((listing) => {
      return {
        nfdName: listing.name,
        name: listing.name,
        assetId: listing.asaID?.toString() as string,
        unitName: 'NFD',
        creatorAddress: listing.owner as string,
        price: listing.sellAmount as number,
        imageUrl:
          listing.category === 'curated'
            ? `/img/nfd-image-placeholder-bg_gold.jpg`
            : `/img/nfd-image-placeholder-bg_gray.jpg`,
        marketplace: 'NFDomains' as Marketplace,
        marketplaceLink: `${NFDOMAINS_BASE_URL}/name/${listing.name}`
      }
    })
    .sort((a, b) => a.name.localeCompare(b.name))
}

export const getNfdomainsList = async (
  address: string,
  ssr?: boolean,
  options?: AxiosRequestConfig
) => {
  const data = await getByQuery({ owner: address, view: 'brief', limit: 200 }, ssr, options)

  if (!data || !data.length) {
    throw new Error('There are no NFDomains listings for this address')
  }

  return mapList(data)
}
