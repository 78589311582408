export const IconPeraExplorer = ({ className = '' }) => (
  <svg viewBox="0 0 32.77 29.55" stroke="currentColor" fill="currentColor" className={className}>
    <path d="M8.51,0C5.86,0,3.36,1.13,1.1,3.28l-.2,.19v4.58l1.13-1.2c2.25-2.4,4.31-3.5,6.16-3.5s3.38,.95,4.63,3.1c1.26,2.17,1.9,4.77,1.9,7.85v.12L0,29.55H4.7l10.36-10.72c.23,1.62,.49,3.22,1.03,4.52h0v.02c.73,1.6,1.59,2.85,2.6,3.71,1.84,1.61,3.71,2.48,5.57,2.48,2.62,0,5.11-1.13,7.41-3.27l.21-.19v-4.58l-1.13,1.2c-2.25,2.4-4.33,3.5-6.21,3.5s-3.33-.92-4.58-2.99c-1.19-2.07-1.8-4.56-1.87-7.45L32.77,.55h-4.63l-10.4,10.71c-.21-1.69-.45-3.35-.99-4.72-.69-1.72-1.52-3.06-2.54-4h0v-.02c-1.92-1.65-3.82-2.53-5.69-2.53h0Z" />
  </svg>
)

export const IconAlgoXNft = ({ className = '' }) => (
  <svg className={className} viewBox="0 0 54.78 37.79">
    <polygon
      points="12.35 37.79 17.05 37.79 17.05 33.69 29.29 33.69 29.29 37.79 33.67 37.79 33.67 29.46 37.91 29.46 37.91 25.22 42.07 25.22 42.07 20.99 46.24 20.99 46.24 17.25 50.33 17.25 50.33 20.92 54.78 20.92 54.78 16.82 50.45 16.82 50.45 12.47 46.35 12.47 46.35 16.71 41.93 16.71 41.93 8.42 38.07 8.42 38.07 4 25.36 4 25.36 0 20.99 0 20.99 4.05 17.05 4.05 17.05 0 12.35 0 12.35 4.05 8.38 4.05 8.38 12.28 0 12.28 0 25.32 8.28 25.32 8.28 29.74 12.28 29.74 12.35 37.79"
      fill="#f51a8e"
    />
    <rect x="12.38" y="8.38" width="4.68" height="4.33" fill="#fff" />
    <rect x="12.38" y="8.38" width="4.68" height="4.33" />
  </svg>
)
