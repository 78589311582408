import { useEffect, useMemo, useState } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useWallet } from '@txnlab/use-wallet-react'
// import { useRouterScroll } from '@moxy/next-router-scroll'
import { getByQuery } from 'api/hooks/v2/useSearch'
import useDebounce from 'hooks/useDebounce'
import { useSegmentsTabStore } from 'store/state/segmentsStore'
import { getGridLimit, getListLimit } from 'components/Dashboard/Dashboard.utils'
import { getGridCellHeight } from 'components/NfdResult/NfdResult.utils'
import { isBreakpoint } from 'helpers/utilities'
import { gridProperties } from 'components/ItemLayout/ItemLayout.constants'
import { topBarHeight } from 'src/data/layout'
import type { NfdRecord } from 'api/api-client'
import type { ViewSetting } from 'components/Dashboard/Controls'
import type { PagedSegments } from './Segments.types'
import type { ICustomError } from 'api/customError'

interface UseSegments {
  nfd: NfdRecord
  searchRef: React.RefObject<HTMLInputElement>
}

export default function useSegments({ nfd, searchRef }: UseSegments) {
  const viewSetting = useSegmentsTabStore((state) => state.view)
  const setViewSetting = useSegmentsTabStore((state) => state.setView)

  const [searchText, setSearchText] = useState('')
  const debouncedSearchText = useDebounce(searchText, 300)

  const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
  }

  const resetSearchText = () => {
    setSearchText('')
    if (searchRef.current) {
      searchRef.current.focus()
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      resetSearchText()
    }
  }

  const { activeAccount } = useWallet()
  const isOwner = nfd.owner === activeAccount?.address

  const params = {
    parentAppID: nfd.appID,
    ...(debouncedSearchText && { prefix: debouncedSearchText })
  }

  const limit = getGridListLimit({ viewSetting })

  const fetchSegments = async (page: number): Promise<PagedSegments> => {
    setFetchedPages((prev) => new Set(prev.add(page - 1)))

    const offset = (page - 1) * limit
    const results = (await getByQuery({ ...params, offset, limit })) || []

    return {
      results,
      nextPage: results.length === limit ? page + 1 : null
    }
  }

  const {
    data: segments,
    isInitialLoading,
    error,
    fetchNextPage,
    isFetchingNextPage,
    // isFetched,
    hasNextPage,
    refetch
  } = useInfiniteQuery<PagedSegments, ICustomError>(
    ['segments', params],
    ({ pageParam = 1 }) => fetchSegments(pageParam),
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => lastPage.nextPage
    }
  )

  const hasSegments = useMemo(() => {
    return segments?.pages.some((page) => page.results.length > 0)
  }, [segments])

  const [fetchedPages, setFetchedPages] = useState(new Set<number>())

  useEffect(() => {
    setFetchedPages(new Set())
  }, [debouncedSearchText])

  // @todo: do we need this?
  // const [hasSetScrollPosition, setHasSetScrollPosition] = useState(false)

  // const { updateScroll } = useRouterScroll()

  // useEffect(() => {
  //   if (isFetched && !hasSetScrollPosition) {
  //     updateScroll()
  //     setHasSetScrollPosition(true)
  //   }
  // }, [hasSetScrollPosition, isFetched, updateScroll])

  const refetchPage = (pageIdx: number) => {
    refetch({ refetchPage: (_, index) => index === pageIdx })
    setFetchedPages((prev) => new Set(prev.add(pageIdx)))
  }

  const isUnlocked = nfd?.properties?.internal?.segmentLocked === '0'

  const hasFilter = debouncedSearchText.length > 0

  return {
    segments,
    isInitialLoading,
    error,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetchPage,
    fetchedPages,
    setFetchedPages,
    activeAccount,
    isOwner,
    isUnlocked,
    viewSetting,
    setViewSetting,
    searchText,
    handleSearchTextChange,
    resetSearchText,
    handleKeyDown,
    hasFilter,
    hasSegments
  }
}

interface GetGridListLimit {
  viewSetting: ViewSetting
  min?: number
  max?: number
}

function getGridListLimit({ viewSetting, min = 4, max = 200 }: GetGridListLimit) {
  const viewportHeight = window.innerHeight || document.documentElement.clientHeight
  const viewportWidth = window.innerWidth || document.documentElement.clientWidth

  const sidebarWidth = isBreakpoint('lg') ? 256 : 0
  const paddingX = isBreakpoint('lg') ? 64 : isBreakpoint('md') ? 48 : 0
  const cardPaddingX = isBreakpoint('sm') ? 48 : 32

  const width = viewportWidth - sidebarWidth - paddingX - cardPaddingX
  const height = viewportHeight - topBarHeight

  const { columnGap, rowGap } = gridProperties

  const gridLimit = getGridLimit({
    width,
    height,
    columnGap,
    rowGap,
    cellMinWidth: isBreakpoint('xl') ? 200 : 160,
    cellHeight: getGridCellHeight
  })

  const listLimit = getListLimit({ height, rowHeight: 80 })

  const calculatedLimit = viewSetting === 'grid' ? gridLimit : listLimit

  const limit = Math.min(Math.max(calculatedLimit, min), max)

  return limit
}
