import axios from 'axios'
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { useMemo, useRef, useState } from 'react'
import useDebounce from 'hooks/useDebounce'
import type { NfdRecord } from 'api/api-client'
import type { ICustomError } from 'api/customError'
import type { AccountAssetsResponse } from 'types/node'

export default function useVault(nfd: NfdRecord) {
  const vaultAddress = nfd.nfdAccount

  const searchRef = useRef<HTMLInputElement>(null)

  const [searchText, setSearchText] = useState('')
  const debouncedSearchText = useDebounce(searchText, 300)

  const fetchAssets = async ({ pageParam = 0 }) => {
    const { data } = await axios.get(`/api/node/account-assets`, {
      params: {
        address: vaultAddress,
        cursor: pageParam,
        ...(debouncedSearchText && { search: debouncedSearchText })
      }
    })
    return data
  }

  const { data, isLoading, error, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery<AccountAssetsResponse, ICustomError>({
      queryKey: ['vault-assets', vaultAddress, { searchText: debouncedSearchText }],
      queryFn: fetchAssets,
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      enabled: !!vaultAddress
    })

  const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
  }

  const resetSearchText = () => {
    setSearchText('')
    if (searchRef.current) {
      searchRef.current.focus()
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      resetSearchText()
    }
  }

  const queryClient = useQueryClient()

  const handleInvalidateQuery = async () => {
    await queryClient.invalidateQueries({
      predicate: ({ queryKey }) => queryKey[0] === 'vault-assets' && queryKey[1] === vaultAddress
    })
  }

  const allAssets = useMemo(() => {
    return data?.pages.flatMap((page) => page.assets) ?? []
  }, [data])

  const isEven = (assetId: number) => {
    const asset = allAssets.find((asset) => asset.id === assetId)
    return asset ? allAssets.indexOf(asset) % 2 === 0 : false
  }

  return {
    data,
    isLoading,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    allAssets,
    vaultAddress,
    searchRef,
    searchText,
    handleKeyDown,
    handleSearchTextChange,
    resetSearchText,
    handleInvalidateQuery,
    isEven
  }
}
