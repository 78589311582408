import { Disclosure, Transition } from '@headlessui/react'
import HistoryItem from './HistoryItem'
import useHistory from './History.hooks'
import type { PanelProps } from 'components/DetailView/DetailView.types'

export default function History({ nfd, context: _context }: PanelProps) {
  const history = useHistory(nfd)

  const renderHistory = () => {
    if (!history || !history.length) {
      return null
    }

    return history.map((historyItems, historyItemsIdx) => {
      if (historyItems.length === 1) {
        return historyItems.map((historyItem) => {
          const isLastItem = historyItemsIdx === history.length - 1

          return (
            <div key={`history-item-${historyItemsIdx}`}>
              <HistoryItem
                historyItem={historyItem}
                isCollapsible={false}
                isOpen={false}
                isLastItem={isLastItem}
              />
            </div>
          )
        })
      }

      return (
        <div key={`history-items-${historyItemsIdx}`}>
          <Disclosure>
            {({ open }) => (
              <>
                {historyItems.map((historyItem, historyItemIdx) => {
                  const isCollapsible = historyItems.length > 1
                  const isLastItem = historyItem.isMain
                    ? historyItemsIdx === history.length - 1
                    : open && historyItemIdx === historyItems.length - 1

                  if (historyItem.isMain) {
                    return (
                      <Disclosure.Button key={`isMain-${historyItemIdx}`}>
                        <HistoryItem
                          historyItem={historyItem}
                          isCollapsible={isCollapsible}
                          isOpen={open}
                          isLastItem={isLastItem}
                        />
                      </Disclosure.Button>
                    )
                  }

                  return (
                    <Transition
                      key={`child-${historyItemIdx}`}
                      enter="transition duration-250 ease-out transform"
                      enterFrom="transform opacity-0 -translate-y-full"
                      enterTo="transform opacity-100 translate-y-0"
                    >
                      <Disclosure.Panel>
                        <HistoryItem
                          historyItem={historyItem}
                          isCollapsible={isCollapsible}
                          isOpen={open}
                          isLastItem={isLastItem}
                        />
                      </Disclosure.Panel>
                    </Transition>
                  )
                })}
              </>
            )}
          </Disclosure>
        </div>
      )
    })
  }

  return (
    <div className="flex-1 bg-gray-100 pt-2 pb-8 5xl:pt-4 dark:bg-gray-950/40">
      <div className="max-w-5xl mx-auto p-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow overflow-hidden sm:rounded-lg dark:bg-gray-850 dark:highlight">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
              History
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500 dark:text-gray-500">
              Changes to the metadata for this NFD
            </p>
          </div>
          <div className="border-t border-gray-200 dark:border-gray-750/75">
            <div className="px-4 py-5 sm:px-6">{renderHistory()}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
