import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { API_BASE_URL } from 'src/data/constants'
import queryString from 'query-string'
import { captureException } from '@sentry/nextjs'
import { getAlgoXNftAuctionList, getAlgoXNftBuyNowList } from './algoXNFT/utils'
import { getRandGalleryList } from './randGallery/utils'
import { getNfdomainsList } from './nfdomains/utils'
import { getShuflList } from './shufl/utils'
import { getExaList } from './exa/utils'
import { chunkArray } from 'helpers/utilities'

export type SupportedMarketplace = 'RandGallery' | 'AlgoXNFT' | 'Shufl' | 'Exa' | 'NFDomains'

export type Listing = {
  nfdName?: string
  name: string
  assetId: string
  unitName?: string
  price: number
  priceAsset?: AsaInfo
  creatorAddress: string
  imageUrl: string
  marketplace: SupportedMarketplace
  marketplaceLink: string
  auction?: {
    winningBid: number
    startingBid: number
    endingTime: string
  }
  basketSize?: number
}

export type GetListingsResponse = Listing[]

export type AsaInfo = {
  asaID: number
  creator: string
  decimals: number
  imageUrl: string
  name: string
  totalCreated: 1
  unitName: string
  url: string
}

export const getASAInfo = async (
  assetIds: Array<string | number>,
  ssr?: boolean,
  options?: AxiosRequestConfig
) => {
  const chunked = chunkArray(assetIds.map((id) => id.toString()))

  const responses = await Promise.all(
    chunked.map((chunk) => {
      return axios.get<AsaInfo[]>(`${API_BASE_URL}/nfd/nfts/asas`, {
        params: {
          id: chunk
        },
        paramsSerializer: (params) => {
          return queryString.stringify(params)
        },
        ...(ssr && { headers: { origin: process.env.NEXT_PUBLIC_BASE_URL as string } }),
        ...options
      })
    })
  )

  return responses.reduce((acc: AsaInfo[], response: AxiosResponse<AsaInfo[], AxiosError>) => {
    return [...acc, ...response.data]
  }, [] as AsaInfo[])
}

export const getListings = async (
  addresses: string[],
  ssr?: boolean,
  options?: AxiosRequestConfig
) => {
  const results = await Promise.allSettled([
    ...addresses.map((address) => getAlgoXNftBuyNowList(address, ssr, options)),
    ...addresses.map((address) => getAlgoXNftAuctionList(address, ssr, options)),
    ...addresses.map((address) => getRandGalleryList(address, ssr, options)),
    ...addresses.map((address) => getExaList(address, ssr, options)),
    ...addresses.map((address) => getNfdomainsList(address, ssr, options)),
    getShuflList(addresses, ssr, options)
  ])

  const listings = results.reduce<Listing[]>((acc, current) => {
    if (current.status === 'fulfilled') {
      const listing = current.value
      acc.push(...listing)
    }
    return acc
  }, [])

  return listings
}

type UseNFTListingsArgs = {
  addresses: string[]
  options?: UseQueryOptions<GetListingsResponse, AxiosError>
}

export default function useNFTListings({ addresses, options }: UseNFTListingsArgs) {
  const result = useQuery<GetListingsResponse, AxiosError>(
    ['nft-listings', addresses],
    () => getListings(addresses),
    {
      ...options
    }
  )

  if (result.error) {
    captureException(result.error)
  }

  return result
}
