import { useRef } from 'react'
import { isMobile } from 'react-device-detect'
import { CSSTransition } from 'react-transition-group'
import AssetMedia from 'components/AssetMedia'
import TextAvatar from 'components/TextAvatar'
import galleryImageLoader from 'helpers/galleryImageLoader'

interface AssetPreviewProps {
  src: string
  assetId: string
  onClick: () => void
  nfdName?: string
  isInVault?: boolean
}

export default function AssetPreview({
  src,
  assetId,
  onClick,
  nfdName,
  isInVault = false
}: AssetPreviewProps) {
  const badgeRef = useRef<HTMLSpanElement>(null)

  const renderMedia = () => {
    if (nfdName) {
      return (
        <TextAvatar
          name={nfdName}
          imgSrc={src}
          className="absolute inset-0 group-hover:opacity-75 object-cover w-full h-full dark:group-hover:ring-inset dark:group-hover:ring-gray-900 dark:group-hover:ring-1"
        />
      )
    }

    return (
      <AssetMedia
        src={src}
        alt={assetId}
        className="object-cover w-full h-full"
        loader={galleryImageLoader}
        sizes="(max-width: 640px) 100vw, 360px"
        fill
        options={{ optimizeGifs: true, stateBgColor: 'transparent' }}
        videoJsOptions={{
          preload: isMobile ? 'auto' : 'metadata',
          controls: isMobile,
          fluid: true,
          fill: true
        }}
      />
    )
  }

  return (
    <button
      type="button"
      className="overflow-hidden hover:scale-[1.02] transition hover:shadow-md origin-bottom w-full bg-black/5 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 [-webkit-transform-style:preserve-3d] rounded-lg dark:bg-white/5 dark:ring-offset-gray-850"
      onClick={onClick}
    >
      <div className="relative aspect-square overflow-hidden">
        {renderMedia()}

        {/* @ts-expect-error Not a valid JSX element */}
        <CSSTransition
          in={isInVault}
          nodeRef={badgeRef}
          timeout={500}
          classNames="vault-badge"
          unmountOnExit
        >
          <div className="absolute top-1 right-2">
            <span
              ref={badgeRef}
              className="inline-flex items-center rounded-md bg-brand-500 px-1.5 py-0.5 text-xs font-medium text-white"
            >
              Vaulted
            </span>
          </div>
        </CSSTransition>
      </div>
    </button>
  )
}
