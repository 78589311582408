export enum HistoryType {
  Claim = 'Claimed',
  Many = 'Updated',
  Mint = 'Minted',
  Purchase = 'Purchased',
  Sell = 'Offered for sale',
  CancelSell = 'Cancelled sale',
  Auction = 'Placed on auction',
  Update = 'Changed',
  Verify = 'Verified',
  Remove = 'Removed',
  Internal = 'Internal',
  Upgrade = 'Upgraded contract'
}

export type HistoryData = {
  type: HistoryType
  isMain: boolean
  text: string
  key?: string
  value?: string
  date: string
  price?: number | string
  address?: string
}
