import type { NfdRecord } from 'api/api-client'

export enum Context {
  Profile = 'PROFILE',
  Manage = 'MANAGE',
  Auction = 'AUCTION',
  Buy = 'BUY'
}

export enum Sections {
  About = 'details',
  Assets = 'assets',
  Gallery = 'gallery',
  Vault = 'vault',
  Segments = 'segments',
  Sales = 'sales',
  Offers = 'offers',
  History = 'history'
}

export type DetailProps = {
  nfd: NfdRecord
  context: Context
  showTip: boolean
}

export type PanelProps = Omit<DetailProps, 'showTip'>

export type MoreTabViews = Sections.Sales | Sections.Offers | Sections.History
