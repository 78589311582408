import { HistoryType } from 'components/DetailView/History/History.types'
import {
  HiViewList,
  HiDatabase,
  HiTag,
  HiShoppingBag,
  HiBadgeCheck,
  HiDocumentRemove,
  HiInformationCircle,
  HiBackspace,
  HiOutlineLockClosed
} from 'react-icons/hi'
import { HiSparkles } from 'react-icons/hi2'
import { RiAuctionFill } from 'react-icons/ri'
import { classNames } from 'src/helpers/utilities'

type IconProps = {
  isMain?: boolean
  type: HistoryType
  size?: 'sm' | 'lg'
  color?: 'brand' | 'gray'
}

export default function Icon({ type, size = 'lg', isMain, color = 'gray' }: IconProps) {
  const iconContainerClasses = classNames(
    size === 'sm' ? 'h-5 w-5' : 'h-8 w-8',
    isMain
      ? 'bg-gray-50 ring-brand-400 ring-offset-2 dark:bg-gray-800'
      : 'bg-white ring-gray-300 dark:bg-gray-850',
    'rounded-full flex items-center justify-center ring-gray-300 ring-2 dark:ring-gray-700 dark:ring-offset-gray-850'
  )

  const iconClasses = classNames(
    color === 'brand' ? 'text-brand-500' : '',
    color === 'gray' ? 'text-gray-500' : '',
    'h-5 w-5'
  )

  const renderIcon = () => {
    switch (type) {
      case HistoryType.Many:
        return <HiViewList className={iconClasses} aria-hidden="true" />
      case HistoryType.Mint:
        return <HiSparkles className={iconClasses} aria-hidden="true" />
      case HistoryType.Purchase:
        return <HiShoppingBag className={iconClasses} aria-hidden="true" />
      case HistoryType.Claim:
        return <HiShoppingBag className={iconClasses} aria-hidden="true" />
      case HistoryType.Update:
        return <HiDatabase className={iconClasses} aria-hidden="true" />
      case HistoryType.Auction:
        return <RiAuctionFill className={iconClasses} aria-hidden="true" />
      case HistoryType.Verify:
        return <HiBadgeCheck className={iconClasses} aria-hidden="true" />
      case HistoryType.Remove:
        return <HiDocumentRemove className={iconClasses} aria-hidden="true" />
      case HistoryType.Internal:
        return <HiInformationCircle className={iconClasses} aria-hidden="true" />
      case HistoryType.Sell:
        return <HiTag className={iconClasses} aria-hidden="true" />
      case HistoryType.CancelSell:
        return <HiBackspace className={iconClasses} aria-hidden="true" />
      case HistoryType.Upgrade:
        return <HiOutlineLockClosed className={iconClasses} aria-hidden="true" />
    }
  }

  return (
    <div className="relative px-1">
      <div className={iconContainerClasses}>{renderIcon()}</div>
    </div>
  )
}
