import Tooltip from 'components/Tooltip'
import Image from 'next/image'
import { IconAlgoXNft } from './Gallery.icons'

export default function SupportedMarketplaces() {
  return (
    <div className="mb-4 -mt-1.5 xs:flex xs:items-center xs:justify-center xs:space-x-4 sm:justify-start sm:space-x-5">
      <span className="hidden xs:block text-xs font-semibold uppercase tracking-wide text-gray-500 py-2 border border-transparent">
        Marketplaces
        <br />
        supported
      </span>
      <div
        aria-hidden="true"
        className="hidden xs:block w-px h-10 bg-gray-200 mx-4 dark:bg-gray-750"
      />
      <ul className="flex items-center justify-center flex-wrap space-x-2 sm:space-x-3">
        <li>
          <Tooltip text="AlgoXNFT">
            <a
              href="https://algoxnft.com/"
              className="h-10 w-10 inline-flex items-center justify-center rounded-xl border border-transparent bg-gray-100/50 shadow-sm hover:bg-gray-100 outline-brand-500 dark:bg-gray-500/10 dark:hover:bg-gray-500/20"
              target="_blank"
              rel="noreferrer"
            >
              <IconAlgoXNft className="h-6 w-6" />
            </a>
          </Tooltip>
        </li>
        <li>
          <Tooltip text="Rand Gallery">
            <a
              href="https://www.randgallery.com/"
              className="h-10 w-10 inline-flex items-center justify-center rounded-xl border border-transparent bg-gray-100/50 shadow-sm hover:bg-gray-100 outline-brand-500 dark:bg-gray-500/10 dark:hover:bg-gray-500/20"
              target="_blank"
              rel="noreferrer"
            >
              <Image
                alt="randgallery icon"
                src="/img/icon-randgallery.png"
                height={24}
                width={24}
              />
            </a>
          </Tooltip>
        </li>
        <li>
          <Tooltip text="Exa Market">
            <a
              href="https://exa.market/"
              className="h-10 w-10 inline-flex items-center justify-center rounded-xl border border-transparent bg-gray-100/50 shadow-sm hover:bg-gray-100 outline-brand-500 dark:bg-gray-500/10 dark:hover:bg-gray-500/20"
              target="_blank"
              rel="noreferrer"
            >
              <Image alt="exa icon" src="/img/icon-exa.png" height={24} width={24} />
            </a>
          </Tooltip>
        </li>
        <li>
          <Tooltip text="Shufl">
            <a
              href="https://shufl.app/"
              className="h-10 w-10 inline-flex items-center justify-center rounded-xl border border-transparent bg-gray-100/50 shadow-sm hover:bg-gray-100 outline-brand-500 dark:bg-gray-500/10 dark:hover:bg-gray-500/20"
              target="_blank"
              rel="noreferrer"
            >
              <Image alt="shufl icon" src="/img/icon-shufl.png" height={24} width={24} />
            </a>
          </Tooltip>
        </li>
      </ul>
    </div>
  )
}
