import Link from 'next/link'
import type { HistoryData } from 'components/DetailView/History/History.types'
import { HistoryType } from 'components/DetailView/History/History.types'
import Icon from 'components/DetailView/History/Icon'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
import { classNames, convertAlgosToMicroalgos } from 'src/helpers/utilities'
import { HiChevronDown } from 'react-icons/hi'
import AlgoSymbol from 'components/AlgoSymbol'
import AlgoPrice from 'components/AlgoPrice'
import Linkify from 'components/Linkify'
import { useMemo } from 'react'
import { addAlgoProtocol } from 'helpers/linkify'

/** @todo abstract dayjs into a hook so it can be used elswhere */
dayjs.extend(utc)
dayjs.extend(relativeTime)

type HistoryItemProps = {
  historyItem: HistoryData
  isCollapsible: boolean
  isOpen: boolean
  isLastItem: boolean
}

export default function HistoryItem({
  historyItem,
  isCollapsible,
  isOpen,
  isLastItem
}: HistoryItemProps) {
  const historyValue = useMemo(() => {
    if (historyItem.value === undefined) {
      return historyItem.value
    }

    return addAlgoProtocol(historyItem.value)
  }, [historyItem.value])

  const renderHistory = () => {
    if (historyItem.isMain) {
      return (
        <>
          <span className="text-gray-700 font-medium truncate dark:text-gray-300">
            {historyItem.type} {historyItem.text}
            {historyItem.address && (
              <Link
                href={`/address/${historyItem.address}`}
                className="text-brand-500 truncate hover:text-brand-600 outline-brand-500 rounded-md px-1"
              >
                {historyItem.address}
              </Link>
            )}
          </span>
        </>
      )
    }

    if (historyItem.type === HistoryType.Remove) {
      return (
        <>
          <span className="mr-1 inline-flex items-center rounded-sm bg-gray-100 px-1.5 py-0.5 text-sm font-medium text-gray-600 font-mono scale-90 origin-left whitespace-nowrap dark:bg-gray-800 dark:text-gray-300">
            {historyItem.key}
          </span>
          <span className="text-gray-500 inline-flex items-center dark:text-gray-500">
            (Removed)
          </span>
        </>
      )
    }

    if (historyItem.type === HistoryType.Verify) {
      return (
        <>
          <span className="mr-1 inline-flex items-center rounded-sm bg-gray-100 px-1.5 py-0.5 text-sm font-medium text-gray-600 font-mono scale-90 origin-left whitespace-nowrap dark:bg-gray-800 dark:text-gray-300">
            {historyItem.key}
          </span>
          <span className="inline-flex items-center truncate font-medium dark:text-gray-100">
            <Linkify>{historyValue}</Linkify>
          </span>
        </>
      )
    }

    return (
      <>
        <span className="mr-1 inline-flex items-center rounded-sm bg-gray-100 px-1.5 py-0.5 text-sm font-medium text-gray-600 font-mono scale-90 origin-left whitespace-nowrap dark:bg-gray-800 dark:text-gray-300">
          {historyItem.key}
        </span>
        {/* @todo: truncate Linkify links */}
        {/* @todo: show prefs as font-mono */}
        <span className="inline-flex items-center truncate font-medium dark:text-gray-100">
          <Linkify>{historyValue}</Linkify>
        </span>
      </>
    )
  }

  const renderIcon = () => {
    if (historyItem.isMain) {
      return <Icon type={historyItem.type} size={'lg'} isMain={historyItem.isMain} color="brand" />
    }

    return <Icon type={historyItem.type} />
  }

  const renderDate = () => {
    return (
      historyItem.isMain && (
        <span className="text-gray-400 dark:text-gray-500">
          {dayjs(historyItem.date).fromNow()}
        </span>
      )
    )
  }

  const renderPrice = () => {
    return (
      historyItem.price && (
        <span className="text-gray-800 ml-2 bg-gray-100 font-semibold px-2.5 min-w-fit rounded-full leading-normal dark:bg-gray-800 dark:text-gray-300">
          {typeof historyItem.price == 'number' ? (
            <AlgoPrice price={convertAlgosToMicroalgos(historyItem.price)} />
          ) : (
            <>
              <AlgoSymbol /> {historyItem.price}
            </>
          )}
        </span>
      )
    )
  }

  const renderChevron = () => {
    return (
      historyItem.isMain &&
      isCollapsible && (
        <HiChevronDown
          className={`transition-transform ${
            isOpen ? 'transform rotate-180' : ''
          } w-5 h-5 ml-1 text-gray-400 inline dark:text-gray-600`}
        />
      )
    )
  }

  const renderConnector = () => {
    return (
      !isLastItem && (
        <span
          className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-300 dark:bg-gray-700"
          aria-hidden="true"
        />
      )
    )
  }

  return (
    <div
      className={classNames(
        isLastItem ? 'pb-4' : 'pb-6',
        'relative max-w-xs sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-max'
      )}
    >
      {renderConnector()}
      <div className="relative flex items-center space-x-3 w-full">
        <div className="relative flex items-center rounded-full">{renderIcon()}</div>
        <div className="min-w-0 flex-1">
          <div className="text-sm text-left">
            <p className="flex items-center rounded-full">
              {renderHistory()}
              {renderPrice()}
              {renderChevron()}
            </p>
            {renderDate()}
          </div>
        </div>
      </div>
    </div>
  )
}
