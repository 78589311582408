import { useEffect, useState, useMemo, useCallback } from 'react'
import axios, { AxiosError } from 'axios'
import { useInfiniteQuery } from '@tanstack/react-query'
import { NfdRecord } from 'src/api/api-client'
import type { Asset, PagedGalleryData, UseGalleryLimitArgs, LightboxImage } from './Gallery.types'
import useNFTListings, { Listing } from 'api/hooks/useNFTListings'
import { API_BASE_URL } from 'src/data/constants'
import useDebounce from 'hooks/useDebounce'
import { isMobile } from 'react-device-detect'
import { useStore } from 'store/state/store'
import { topBarHeight } from 'src/data/layout'
import { getNfdHasDefaultAvatar, selectOptimisticUpdates } from './Gallery.utils'
import { queryTypes, useQueryState } from 'hooks/useHydratedQueryState'
// import useRelativeTime from 'hooks/useRelativeTime'
import { useOptimisticUpdateStore } from 'store/index'

enum GalleryView {
  Owned = 'owned',
  ForSale = 'for-sale',
  Creations = 'creations'
}

export default function useGallery(nfd: NfdRecord) {
  const [lightbox, setLightbox] = useState<LightboxImage>({
    isOpen: false,
    imageUrl: '',
    imageAlt: '',
    asaId: '',
    nfdName: ''
  })

  const [viewToggle, setViewToggle] = useQueryState<GalleryView>(
    'show',
    queryTypes.stringEnum(Object.values(GalleryView)).withDefault(GalleryView.Owned)
  )

  // useRelativeTime({
  //   future: 'in %s',
  //   past: '%s ago',
  //   s: '%ss',
  //   m: '1m',
  //   mm: '%dm',
  //   h: '1h',
  //   hh: '%dh',
  //   d: '1d',
  //   dd: '%dd',
  //   M: '1mo',
  //   MM: '%dmo',
  //   y: '1y',
  //   yy: '%dy'
  // })

  const showNFDsInGallery = useStore((state) => state.showNFDsInGallery)
  const setShowNFDsInGallery = useStore((state) => state.setShowNFDsInGallery)

  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce(query, 500)

  const addresses = [...new Set([nfd.owner as string, ...(nfd.caAlgo ? nfd.caAlgo : [])])]

  const listingsQuery = useNFTListings({
    addresses,
    options: {
      enabled: viewToggle === GalleryView.ForSale
    }
  })

  const filterListings = (listing: Listing) => {
    return (
      (showNFDsInGallery ? true : listing.unitName !== 'NFD') &&
      (debouncedQuery === '' ||
        listing.name.toLowerCase().includes(debouncedQuery.toLowerCase()) ||
        listing.assetId === debouncedQuery)
    )
  }

  const handleChangeToggle = (view: GalleryView) => {
    setViewToggle(view, {
      scroll: false,
      shallow: true
    })
  }

  const handleOpenLightbox = (
    imageUrl: string,
    imageAlt: string,
    asaId: string,
    nfdName?: string
  ) => {
    setLightbox({ isOpen: true, imageUrl, imageAlt, asaId, nfdName: nfdName })
  }

  const handleCloseLightbox = () => {
    // Prevent "fallback image" from flashing while closing lightbox
    setLightbox((prev) => ({ ...prev, isOpen: false }))
    setTimeout(() => {
      setLightbox({ isOpen: false, imageUrl: '', imageAlt: '', asaId: '', nfdName: '' })
    }, 300)
  }

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
  }

  const clearQuery = () => {
    setQuery('')
  }

  const handleToggleShowNFDs = () => {
    setShowNFDsInGallery(!showNFDsInGallery)
  }

  const [lastPage, setLastPage] = useState<number | null>(null)

  const fetchNfts = async (page: number, limit = 20) => {
    setLastPage(page)

    const { data: results } = await axios.get<Asset[]>(
      `${API_BASE_URL}/nfd/nfts/${nfd.name}?limit=${limit}&offset=${page * limit}${
        debouncedQuery !== '' ? `&q=${encodeURIComponent(debouncedQuery)}` : ''
      }${viewToggle === 'creations' ? '&creations=true' : ''}`
    )

    return {
      results,
      nextPage: results.length === limit ? page + 1 : null
    }
  }

  const queryKey = [
    'nfts-gallery',
    nfd.name,
    { query: debouncedQuery, creations: viewToggle === 'creations' }
  ]

  const getOptimisticUpdates = useOptimisticUpdateStore((state) => state.getOptimisticUpdates)

  const { data, isInitialLoading, error, fetchNextPage, isFetchingNextPage, hasNextPage, refetch } =
    useInfiniteQuery<PagedGalleryData, AxiosError>(
      queryKey,
      ({ pageParam = 0 }) => fetchNfts(pageParam),
      {
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPage) => lastPage.nextPage,
        select: (data) => {
          const updates = getOptimisticUpdates({ queryKey: ['nfts-gallery', nfd.name] })
          return selectOptimisticUpdates(data, updates)
        }
      }
    )

  const nfts = useFilteredResults({
    pages: data?.pages,
    lastPage,
    hasNextPage,
    fetchNextPage,
    showNFDsInGallery
  })

  const nftsTotal = useMemo(() => {
    return nfts?.pages.flatMap((page) => page.results).length
  }, [nfts?.pages])

  const lightBoxImages: LightboxImage[] | undefined = useMemo(() => {
    if (viewToggle === GalleryView.ForSale) {
      return listingsQuery.data?.map((listing) => {
        return {
          asaId: listing.assetId,
          imageAlt: listing.assetId,
          imageUrl: listing.imageUrl,
          isOpen: true,
          nfdName: listing.nfdName
        }
      })
    }

    return nfts?.pages
      .flatMap((page) => page.results)
      .map((asset) => {
        return {
          asaId: `${asset.asaID}`,
          imageAlt: `${asset.asaID}`,
          imageUrl: asset.imageUrl,
          isOpen: true,
          nfdName: getNfdHasDefaultAvatar(asset) ? asset.name : undefined
        }
      })
  }, [nfts?.pages, listingsQuery.data, viewToggle])

  const currentLightboxIndex = useMemo(
    () => lightBoxImages?.findIndex((nft) => nft.asaId === lightbox.asaId),
    [lightbox.asaId, lightBoxImages]
  )

  const advanceIndex = (forward: boolean, currentIndex: number, numOfItems: number) => {
    return (currentIndex + (forward ? 1 : numOfItems - 1)) % numOfItems
  }

  const hasMoreImagesToLoad = useMemo(() => {
    return viewToggle === GalleryView.ForSale ? false : hasNextPage
  }, [viewToggle, hasNextPage])

  const shouldShowPrevButton = useMemo(() => {
    return currentLightboxIndex === 0 && hasMoreImagesToLoad ? false : true
  }, [currentLightboxIndex, hasMoreImagesToLoad])

  const showPrevNext: Array<'prev' | 'next'> = useMemo(() => {
    if (currentLightboxIndex === 0 && hasMoreImagesToLoad) {
      return ['next']
    }

    if (
      viewToggle === GalleryView.ForSale &&
      listingsQuery.data &&
      listingsQuery.data.length <= 1
    ) {
      return []
    }

    if (
      viewToggle !== GalleryView.ForSale &&
      nfts?.pages &&
      nfts.pages.flatMap((page) => page.results).length <= 1
    ) {
      return []
    }

    return ['prev', 'next']
  }, [currentLightboxIndex, hasMoreImagesToLoad, listingsQuery.data, nfts?.pages, viewToggle])

  const handleAdvanceLightboxImage = useCallback(
    (forward: boolean) => {
      if (!lightBoxImages || currentLightboxIndex === undefined || currentLightboxIndex === -1) {
        return
      }

      if (currentLightboxIndex === 0 && !forward && hasMoreImagesToLoad) {
        return
      }

      const isAlmostLast = currentLightboxIndex === lightBoxImages.length - 4

      // If the current image is 3rd to last one in the gallery, fetch the next page
      if (isAlmostLast && hasMoreImagesToLoad) {
        fetchNextPage()
      }

      const nextIndex = advanceIndex(forward, currentLightboxIndex, lightBoxImages.length)

      setLightbox(lightBoxImages[nextIndex])
    },
    [currentLightboxIndex, fetchNextPage, hasMoreImagesToLoad, lightBoxImages]
  )

  useEffect(() => {
    refetch()
  }, [showNFDsInGallery, refetch])

  useEffect(() => {
    const handlePressArrowKey = (e: KeyboardEvent) => {
      if (!['ArrowLeft', 'ArrowRight'].includes(e.key)) {
        return
      }

      handleAdvanceLightboxImage(e.key === 'ArrowRight')
    }

    !isMobile && window.addEventListener('keydown', handlePressArrowKey)

    return () => {
      !isMobile && window.removeEventListener('keydown', handlePressArrowKey)
    }
  }, [handleAdvanceLightboxImage])

  return {
    lightbox,
    nfts,
    nftsTotal,
    listingsQuery: {
      ...listingsQuery,
      listings: listingsQuery.data?.filter(filterListings)
    },
    viewToggle,
    handleChangeToggle,
    handleOpenLightbox,
    handleCloseLightbox,
    handleAdvanceLightboxImage,
    showNFDsInGallery,
    handleToggleShowNFDs,
    shouldShowPrevButton,
    showPrevNext,
    query,
    queryKey,
    handleQueryChange,
    clearQuery,
    isLoading: isInitialLoading,
    error,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch
  }
}

interface UseFilteredResults {
  pages: PagedGalleryData[] | undefined
  lastPage: number | null
  hasNextPage: boolean | undefined
  fetchNextPage: () => Promise<unknown>
  showNFDsInGallery: boolean
}

function useFilteredResults({
  pages,
  lastPage,
  hasNextPage,
  fetchNextPage,
  showNFDsInGallery
}: UseFilteredResults) {
  // all NFTs (including NFDs)
  const allNfts = useMemo(() => {
    if (pages) {
      return pages.map((page) => {
        return {
          results: page.results
            .filter((asset) => asset.totalCreated / Math.pow(10, asset.decimals) <= 10000)
            .map((asset) => {
              if (asset.imageUrl.includes('nfd-image-placeholder_gray')) {
                asset.imageUrl = '/img/nfd-image-placeholder-bg_gray.jpg'
              }

              if (asset.imageUrl.includes('nfd-image-placeholder_gold')) {
                asset.imageUrl = '/img/nfd-image-placeholder-bg_gold.jpg'
              }

              if (asset.imageUrl.includes('nfd-image-placeholder')) {
                asset.imageUrl = '/img/nfd-image-placeholder-bg.jpg'
              }

              return asset
            })
        }
      })
    }

    return []
  }, [pages])

  // excludes NFDs
  const excludeNfds = useMemo(() => {
    if (allNfts) {
      return allNfts.map((page) => {
        return {
          results: page.results.filter((asset) => asset.unitName !== 'NFD')
        }
      })
    }

    return []
  }, [allNfts])

  // the active (visible) result set, depending on showNFDsInGallery
  const nfts = useMemo(() => {
    if (showNFDsInGallery) {
      return {
        pages: allNfts
      }
    }

    return {
      pages: excludeNfds
    }
  }, [allNfts, excludeNfds, showNFDsInGallery])

  /**
   * If the last page is empty (due to filtering), there won't be an intersection
   * observer to trigger the next page fetch. This will trigger the fetch manually,
   * multiple times if necessary.
   */
  useEffect(() => {
    const needsNextPageFetch =
      hasNextPage && lastPage !== null && nfts.pages?.[lastPage]?.results.length === 0

    if (needsNextPageFetch) {
      console.log('fetching next page...')
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage, lastPage, nfts.pages])

  return nfts
}

export const useGalleryLimit = (options?: UseGalleryLimitArgs) => {
  const { min = 6, max = 20 } = options || {}

  const [limit, setLimit] = useState<number | null>(null)

  const getNumResultsPerPage = () => {
    const isSmScreen = window.matchMedia('(min-width: 640px)').matches
    const isMdScreen = window.matchMedia('(min-width: 768px)').matches
    const isXlScreen = window.matchMedia('(min-width: 1280px)').matches

    const viewportHeight = window.innerHeight || document.documentElement.clientHeight
    const filtersHeight = 116
    const stickyContentHeight = topBarHeight + filtersHeight
    const containerMaxVisibleHeight = Math.max(viewportHeight - stickyContentHeight, 0)
    const containerPaddingTop = 48 // padding-top of container
    const availableHeight = containerMaxVisibleHeight - containerPaddingTop

    const viewportWidth = window.innerWidth || document.documentElement.clientWidth
    const sidebarWidth = 256
    const availableWidth = viewportWidth - sidebarWidth

    const xlGridGapX = 32
    const xlMinElementWidth = 240
    const xlContainerPaddingX = 32
    const xlMaxResultsPerRow = Math.floor(
      (availableWidth - xlContainerPaddingX) / (xlMinElementWidth + xlGridGapX)
    )

    const resultsPerRow = isXlScreen ? xlMaxResultsPerRow : isMdScreen ? 3 : isSmScreen ? 2 : 1

    const rowPaddingY = isMdScreen ? 40 : 16
    const minElementHeight = isMdScreen ? 280 : isSmScreen ? 333 : 388
    const minRowHeight = minElementHeight + rowPaddingY

    const minVisibleRows = Math.ceil(availableHeight / minRowHeight)
    const minVisibleResults = minVisibleRows * resultsPerRow

    return minVisibleResults
  }

  useEffect(() => {
    if (limit === null) {
      const numResultsPerPage = Math.max(getNumResultsPerPage(), min)
      setLimit(numResultsPerPage)
    }
  }, [limit, min])

  const getReturnedLimit = () => {
    const limitNum = Number(limit)

    return Math.min(max, Math.max(min, limitNum))
  }

  return {
    enabled: limit !== null,
    limit: getReturnedLimit()
  }
}
