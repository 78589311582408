import { InfiniteData, QueryKey, useQueryClient } from '@tanstack/react-query'
import { useWallet } from '@txnlab/use-wallet-react'
import algosdk from 'algosdk'
import { useMemo, useState } from 'react'
import { NfdRecord, nfdVerifyRequest } from 'api/api-client'
import { useNameUpdate } from 'api/hooks/useName'
import { usePatchUpdateProperties } from 'api/hooks/usePatchUpdateProperties'
import { usePostSendFromVault } from 'api/hooks/usePostSendFromVault'
import { usePostSendToVault } from 'api/hooks/usePostSendToVault'
import useVerifyConfirm from 'api/hooks/useVerifyConfirm'
import { isVaultsSupported } from 'helpers/versions'
import { updateNfdProperties } from 'helpers/optimisticUpdates'
import { capitalizeFirstLetter } from 'helpers/strings'
import { isValidName } from 'helpers/utilities'
import useErrorToast from 'hooks/useErrorToast'
import { useExplorerStore, useOptimisticUpdateStore } from 'store/index'
import { setGalleryUpdate } from '../Gallery.utils'
import type { ReceiverType } from 'components/NfdLookup/NfdLookup.types'
import type { OnSuccessParams } from 'components/SendModal/SendModal.types'
import type { OperationType } from 'store/state/optimisticUpdates/types'
import type { Asset, PagedGalleryData } from '../Gallery.types'

interface UseActionMenu {
  nfd: NfdRecord
  asset: Asset
  queryKey: QueryKey
  pageIndex: number
  index: number
}

export default function useActionMenu({ nfd, asset, queryKey, pageIndex }: UseActionMenu) {
  const [isUpdating, setIsUpdating] = useState(false)
  const [field, setField] = useState<'avatar' | 'banner'>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isSendModalOpen, setIsSendModalOpen] = useState(false)

  const [receiver, setReceiver] = useState<string | undefined>()
  const [receiverType, setReceiverType] = useState<ReceiverType | undefined>()

  const { activeAddress, activeWalletAddresses } = useWallet()
  const handleError = useErrorToast()

  const selectedExplorer = useExplorerStore((state) => state.selectedExplorer)
  const lookupByAssetId = useExplorerStore((state) => state.lookupByAssetId)

  const optimisticPropertiesUpdate = useNameUpdate()

  const { mutateAsync: updateProperties } = usePatchUpdateProperties({
    toasts: {
      success: `${capitalizeFirstLetter(field as string)} successfully updated`
    },
    onSuccess(data, params) {
      if (!params.body.properties) return
      const newNfd = updateNfdProperties(nfd, params.body.properties)
      optimisticPropertiesUpdate(newNfd)
    }
  })

  const { mutateAsync: submitVerifyConfirm } = useVerifyConfirm({
    loading: 'Verifying...',
    success: 'Verification successful!',
    error: 'Verification failed'
  })

  const handleClickSetField = (field: 'avatar' | 'banner') => {
    setField(field)
    setIsModalOpen(true)
  }

  const handleClickVerify = async () => {
    try {
      setIsUpdating(true)

      if (!activeAddress) {
        throw new Error('No active address')
      }

      if (!field) {
        throw new Error('Field not set')
      }

      const body = {
        sender: activeAddress,
        properties: {
          userDefined: { [field]: asset.asaID.toString() }
        }
      }

      await updateProperties({ name: nfd.name, body })

      const { data } = await nfdVerifyRequest({
        name: nfd.name,
        fieldToVerify: field,
        sender: activeAddress
      })

      if (data.id === undefined || data.challenge === undefined) {
        throw new Error('Verification request failed.')
      }

      await submitVerifyConfirm({
        id: data.id,
        challenge: data.challenge,
        name: nfd.name,
        field,
        asset
      })
    } catch (error) {
      handleError(error)
    } finally {
      reset()
    }
  }

  const handleClickGoBack = () => {
    reset()
  }

  const reset = () => {
    setIsModalOpen(false)
    setTimeout(() => {
      setField(undefined)
      setIsUpdating(false)
    }, 300)
  }

  const canMoveAsset = activeAddress === nfd.owner && asset.unitName?.toLowerCase() !== 'nfd'

  const isInVault = asset.foundIn === nfd.nfdAccount
  const canMoveToVault = canMoveAsset && !isInVault && isVaultsSupported(nfd)
  const canMoveToDepositAccount = canMoveAsset && isInVault

  const sender = useMemo<NfdRecord | string | undefined>(() => {
    if (isInVault) {
      return nfd
    }
    return activeWalletAddresses?.find((address) => address === asset.foundIn)
  }, [asset.foundIn, isInVault, nfd, activeWalletAddresses])

  const canSendAsset = !!sender && asset.unitName?.toLowerCase() !== 'nfd'

  const addOptimisticUpdate = useOptimisticUpdateStore((state) => state.addOptimisticUpdate)
  const queryClient = useQueryClient()

  /**
   * Executes an optimistic update on the gallery data for a given record and operation.
   * The function adds the update to the optimisticUpdates store and immediately
   * updates the React Query cache with the updated gallery data.
   *
   * @param record - The asset or asset ID to be updated or removed in the gallery.
   * @param operation - The type of operation to perform ('update' or 'remove').
   */
  const optimisticUpdate = (record: Asset, operation: OperationType) => {
    addOptimisticUpdate<Asset>({
      queryKey,
      record,
      uniqueKey: 'asaID',
      operation,
      pageIndex
    })

    queryClient.setQueryData(queryKey, (data: InfiniteData<PagedGalleryData> | undefined) =>
      setGalleryUpdate({
        data,
        record,
        operation,
        pageIndex
      })
    )
  }

  /**
   * Handles optimistic updates in the gallery after an asset send operation.
   * Depending on the receiver type and if the receiver is a linked address of
   * the current NFD, the function decides whether to update the asset's
   * `foundIn` property or remove it from the gallery.
   *
   * The function checks the validity of the receiver's address or NFD name and
   * determines the appropriate operation ('update' or 'remove'). It then calls
   * the `optimisticUpdate` function to perform the optimistic update.
   *
   * If the provided params are undefined, or the receiver is not a valid
   * address or NFD name, the function returns without performing any updates.
   *
   * Passed to `SendModal` as the `onSuccess` prop.
   *
   * @param params - An object containing parameters related to the send
   *                 operation and receiver details. It is a union of different
   *                 types of parameters that could be passed for various
   *                 receiver types.
   */
  const handleSendOptimisticUpdate = (params: OnSuccessParams) => {
    if (!params) {
      return
    }

    let receiver: string | undefined
    let operation: OperationType
    let record: Asset

    // Get receiver address/name from params
    if ('body' in params && 'receiver' in params.body) {
      receiver = params.body.receiver
    } else if ('name' in params) {
      receiver = params.name
    } else if ('receiver' in params) {
      receiver = params.receiver
    }

    if (!receiver) {
      return
    }

    // Is `receiver` an NFD (vault)?
    if (isValidName(receiver)) {
      record = {
        ...asset,
        foundIn: nfd.nfdAccount as string
      }

      // Is it the current NFD's vault?
      if (receiver === nfd.name) {
        operation = 'update'
      } else {
        operation = 'remove'
      }
    }

    // Is `receiver` a valid address?
    else if (algosdk.isValidAddress(receiver)) {
      record = {
        ...asset,
        foundIn: receiver
      }

      // Is the address a linked account?
      if (nfd.caAlgo?.includes(receiver)) {
        operation = 'update'
      } else {
        operation = 'remove'
      }
    } else {
      return
    }

    optimisticUpdate(record, operation)
  }

  const { mutateAsync: moveAssetToVault } = usePostSendToVault({
    onSuccess: () => {
      const record: Asset = {
        ...asset,
        foundIn: nfd.nfdAccount as string
      }

      optimisticUpdate(record, 'update')
    }
  })

  const handleClickMoveToVault = async () => {
    try {
      if (!activeAddress) {
        throw new Error('No active address')
      }

      if (!canMoveToVault) {
        throw new Error('Asset cannot be moved to vault')
      }

      if (asset.totalCreated > 1) {
        setReceiver(nfd.name)
        setReceiverType('nfdVault')
        setIsSendModalOpen(true)
      } else {
        const body = {
          sender: activeAddress,
          assets: [asset.asaID],
          amount: 1,
          optInOnly: false
        }

        await moveAssetToVault({ name: nfd.name, body })
      }
    } catch (error) {
      handleError(error)
    }
  }

  const { mutateAsync: moveAssetToDepositAccount } = usePostSendFromVault({
    onSuccess: () => {
      const record: Asset = {
        ...asset,
        foundIn: nfd.depositAccount as string
      }

      optimisticUpdate(record, 'update')
    }
  })

  const handleClickMoveToDepositAccount = async () => {
    try {
      if (!activeAddress) {
        throw new Error('No active address')
      }

      if (!canMoveToDepositAccount || !nfd.depositAccount) {
        throw new Error('Asset cannot be moved to deposit account')
      }

      if (asset.totalCreated > 1) {
        setReceiver(nfd.name)
        setReceiverType('account')
        setTimeout(() => setIsSendModalOpen(true), 0)
      } else {
        const body = {
          sender: activeAddress,
          receiver: nfd.depositAccount,
          assets: [asset.asaID],
          amount: 1
        }

        await moveAssetToDepositAccount({ name: nfd.name, body })
      }
    } catch (error) {
      handleError(error)
    }
  }

  const handleResetReceiver = () => {
    setReceiver(undefined)
    setReceiverType(undefined)
  }

  return {
    isModalOpen,
    field,
    isUpdating,
    setIsModalOpen,
    handleClickSetField,
    handleClickVerify,
    handleClickGoBack,
    reset,
    selectedExplorer,
    lookupByAssetId,
    canMoveToVault,
    canSendAsset,
    sender,
    receiver,
    receiverType,
    isSendModalOpen,
    setIsSendModalOpen,
    handleClickMoveToVault,
    canMoveToDepositAccount,
    handleClickMoveToDepositAccount,
    handleSendOptimisticUpdate,
    handleResetReceiver
  }
}
