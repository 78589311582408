import type { InfiniteData } from '@tanstack/react-query'
import type { Asset, PagedGalleryData } from './Gallery.types'
import type { OperationType, OptimisticUpdate } from 'store/state/optimisticUpdates/types'

export const getNfdHasDefaultAvatar = (asset: Asset) => {
  return (
    asset.unitName?.toLocaleLowerCase() === 'nfd' &&
    asset.imageUrl.includes('nfd-image-placeholder')
  )
}

/**
 * Applies optimistic updates to the given `data` object, which represents
 * an infinite list of paged gallery data, based on the provided `optimisticUpdates`.
 *
 * The function iterates over each page of the data, filtering the updates that
 * are applicable to the current page. For each update, it determines the
 * operation (either 'update' or 'remove') and applies the corresponding
 * modification to the records within the page.
 *
 * If there are no optimistic updates, the original data is returned unchanged.
 *
 * @param data - The original infinite data object containing paged gallery data.
 * @param optimisticUpdates - An array of optimistic updates to apply to the data.
 *
 * @returns The updated data object with optimistic updates applied.
 */
export const selectOptimisticUpdates = (
  data: InfiniteData<PagedGalleryData>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  optimisticUpdates: OptimisticUpdate<any>[]
) => {
  if (optimisticUpdates.length === 0) {
    return data
  }

  const updatedData: InfiniteData<PagedGalleryData> = {
    ...data,
    pages: data.pages.map((page, pageIndex) => {
      const updatesForPage = optimisticUpdates.filter((update) => update.pageIndex === pageIndex)

      if (updatesForPage.length === 0) {
        return page
      }

      const updatedPage: PagedGalleryData = {
        ...page,
        results: [...page.results]
      }

      updatesForPage.forEach(({ record, operation, uniqueKey }) => {
        const key = uniqueKey as keyof Asset

        const updatedAsset = record as Asset
        const index = updatedPage.results.findIndex((record) => record[key] === updatedAsset[key])

        switch (operation) {
          case 'update': {
            if (index !== -1) {
              updatedPage.results[index] = updatedAsset
            }
            break
          }
          case 'remove': {
            if (index !== -1) {
              updatedPage.results.splice(index, 1)
            }
            break
          }
          default:
            break
        }
      })

      return updatedPage
    })
  }

  return updatedData
}

/**
 * Updates the gallery data in the React Query cache by applying the specified
 * operation (either 'update' or 'remove') on the provided record. The function
 * only modifies the specified pageIndex in the data object.
 *
 * The function creates a shallow copy of the original data and page, ensuring
 * immutability. It then iterates over the data pages, updating the records in
 * the specified pageIndex according to the operation type.
 *
 * If the provided data object is undefined, the function returns it unchanged.
 *
 * @param options - An object containing the following properties:
 *   - data: The original infinite data object containing paged gallery data.
 *   - record: The record to be updated or removed, represented as an Asset object or
 *           its asaID property.
 *   - pageIndex: The index of the page in the data object to be updated.
 *
 * @returns The updated data object with the specified operation applied.
 */
export const setGalleryUpdate = (options: {
  data: InfiniteData<PagedGalleryData> | undefined
  record: Asset
  operation: OperationType
  pageIndex: number
}) => {
  const { data, record, operation, pageIndex } = options

  if (!data) {
    return data
  }

  const updatedData: InfiniteData<PagedGalleryData> = {
    ...data,
    pages: data.pages.map((page, pageIdx) => {
      if (pageIdx !== pageIndex) {
        return page
      }

      const updatedPage: PagedGalleryData = {
        ...page,
        results: [...page.results]
      }

      const updatedAsset = record as Asset
      const index = updatedPage.results.findIndex((record) => record.asaID === updatedAsset.asaID)

      switch (operation) {
        case 'update': {
          if (index !== -1) {
            updatedPage.results[index] = updatedAsset
          }
          break
        }
        case 'remove': {
          if (index !== -1) {
            updatedPage.results.splice(index, 1)
          }
          break
        }
        default:
          break
      }

      return updatedPage
    })
  }

  return updatedData
}
