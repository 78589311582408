import dayjs from 'dayjs'
import Image from 'next/image'
import { RiShoppingBasket2Line } from 'react-icons/ri'
import UserThumbnail from 'components/UserThumbnail'
import AlgoPrice from 'components/AlgoPrice'
import Tooltip from 'components/ReactTooltip'
import AssetPreview from '../AssetPreview'
import { formatAssetBalance } from 'helpers/utilities'
import type { AsaInfo, SupportedMarketplace } from 'api/hooks/useNFTListings'

const AlgoXNFTIcon = () => (
  <svg className="w-5 h-5" viewBox="0 0 54.78 37.79">
    <polygon
      points="12.35 37.79 17.05 37.79 17.05 33.69 29.29 33.69 29.29 37.79 33.67 37.79 33.67 29.46 37.91 29.46 37.91 25.22 42.07 25.22 42.07 20.99 46.24 20.99 46.24 17.25 50.33 17.25 50.33 20.92 54.78 20.92 54.78 16.82 50.45 16.82 50.45 12.47 46.35 12.47 46.35 16.71 41.93 16.71 41.93 8.42 38.07 8.42 38.07 4 25.36 4 25.36 0 20.99 0 20.99 4.05 17.05 4.05 17.05 0 12.35 0 12.35 4.05 8.38 4.05 8.38 12.28 0 12.28 0 25.32 8.28 25.32 8.28 29.74 12.28 29.74 12.35 37.79"
      fill="#f51a8e"
    />
    <rect x="12.38" y="8.38" width="4.68" height="4.33" fill="#fff" />
    <rect x="12.38" y="8.38" width="4.68" height="4.33" />
  </svg>
)

const RandGalleryIcon = () => {
  return <Image alt="randgallery icon" src="/img/icon-randgallery.png" height={20} width={20} />
}

const ShuflIcon = () => {
  return <Image alt="shufl icon" src="/img/icon-shufl.png" height={20} width={20} />
}

const ExaIcon = () => {
  return <Image alt="exa icon" src="/img/icon-exa.png" height={20} width={20} />
}

const NFDomainsIcon = () => {
  return <Image alt="nfdomains icon" src="/img/icon-nfdomains.png" height={20} width={20} />
}

const iconMap: { [key in SupportedMarketplace]: () => JSX.Element } = {
  RandGallery: RandGalleryIcon,
  AlgoXNFT: AlgoXNFTIcon,
  Shufl: ShuflIcon,
  Exa: ExaIcon,
  NFDomains: NFDomainsIcon
}

type AssetForSaleProps = {
  name: string
  nfdName?: string
  price?: number
  priceAsset?: AsaInfo
  startingBid?: number
  winningBid?: number
  auctionEndTime?: string
  creatorAddress: string
  imageSrc: string
  assetId: string
  link: string
  marketplace: SupportedMarketplace
  auction?: {
    winningBid: number
    startingBid: number
    endingTime: string
  }
  basketSize?: number
  onImageClick: () => void
}

export default function AssetForSale({
  name,
  nfdName,
  price,
  priceAsset,
  creatorAddress,
  imageSrc,
  assetId,
  link,
  marketplace,
  auction,
  basketSize,
  onImageClick
}: AssetForSaleProps) {
  const isAuction = !!auction
  const isMultiBasket = !!basketSize && basketSize > 1

  const renderPrice = () => {
    const assetPrice = isAuction ? auction.winningBid || auction.startingBid : price

    if (!assetPrice) return null

    if (priceAsset) {
      return (
        <span className="font-medium text-base">
          {formatAssetBalance(
            assetPrice,
            priceAsset.decimals,
            true,
            true,
            12 - priceAsset.unitName.length
          )}{' '}
          {priceAsset.unitName}
        </span>
      )
    }

    return <AlgoPrice price={assetPrice} className="font-medium text-base" />
  }

  const renderFooter = () => {
    return (
      <div className="mt-3">
        <a
          href={link}
          target="_blank"
          rel="noreferrer noopener"
          className="text-gray-900 hover:text-gray-500 bg-gray-100 p-1 rounded-full inline-block outline-brand-500 group dark:bg-gray-800 dark:text-gray-300 dark:hover:text-brand-500"
          data-tooltip-id={`listing-${assetId}`}
          data-tooltip-content={`${isAuction ? 'Bid' : 'Buy'} on ${marketplace}`}
        >
          <div className="flex divide-x divide-gray-300 dark:divide-white/10">
            <div className="flex justify-center items-center px-2">{iconMap[marketplace]()}</div>
            {isAuction && (
              <div className="flex items-center font-medium px-2">
                {dayjs(auction.endingTime).fromNow(true)}
              </div>
            )}
            <div className="flex justify-center items-center pl-2.5 pr-2">{renderPrice()}</div>
            {isMultiBasket && (
              <div className="flex items-center justify-center text-gray-500 px-2 group-hover:text-gray-500 dark:text-gray-400/80 dark:group-hover:text-brand-500">
                <RiShoppingBasket2Line className="mr-0.5" />
                <span className="text-gray-700 dark:text-gray-300 group-hover:text-gray-500 dark:group-hover:text-brand-500">
                  {basketSize}
                </span>
              </div>
            )}
          </div>
        </a>
        <Tooltip id={`listing-${assetId}`} />
      </div>
    )
  }

  return (
    <div>
      <div className="relative">
        <AssetPreview src={imageSrc} assetId={assetId} onClick={onImageClick} nfdName={nfdName} />
      </div>
      <div className="mb-2">
        <div className="flex-1 flex items-center space-x-1 mt-4 min-w-0">
          <h3 className="text-md font-semibold text-gray-900 truncate dark:text-gray-300">
            {name}
          </h3>
        </div>
        <div className="mt-1.5 flex items-center justify-between flex-wrap text-sm">
          <div className="w-full">
            <UserThumbnail
              address={creatorAddress}
              size="sm"
              className="text-gray-400 truncate"
              fallbackClassName="text-gray-400 truncate dark:text-gray-400/80"
              staleTime={Infinity}
            />
          </div>
          {renderFooter()}
        </div>
      </div>
    </div>
  )
}
