import Link from 'next/link'
import { useHydrated } from 'react-hydration-provider'
import { BiCopy } from 'react-icons/bi'
import { HiPencil, HiOutlineChatAlt2, HiOutlineSwitchHorizontal } from 'react-icons/hi'
import { MdOutlineSell } from 'react-icons/md'
import { Context } from 'components/DetailView/DetailView.types'
import Avatar from 'components/DetailView/Header/Avatar'
import Linkify from 'components/Linkify'
import Button from 'components/Button'
import Banner from './Banner'
import Links from './Links'
import ProfileMenu from './ProfileMenu'
import PurchasedTip from './PurchasedTip'
import Donations from './Badges' // @todo: this is going away soon
import Badges from './MeritBadges' // @todo: rename this folder to Badges
import { getAvatarURL, isAvatarVerified } from 'helpers/avatar'
import { copyToClipboard } from 'helpers/copyToClipboard'
import type { NfdRecord } from 'types/api'

import { HeaderGrid, HeaderName, HeaderBio, HeaderAside, HeaderLinks } from './Header.css'

export type HeaderProps = {
  nfd: NfdRecord
  context: Context
  showTip: boolean
}

export default function Header({ nfd, context, showTip }: HeaderProps) {
  const hasVerifiedFields =
    !!nfd.properties?.verified &&
    Object.keys(nfd.properties?.verified).filter((key) => key !== 'caAlgo').length > 0

  const hydrated = useHydrated()

  const renderButtons = () => {
    if (!hydrated) {
      return null
    }

    if (context === Context.Manage) {
      return (
        <>
          <Link href={`/manage/${nfd.name}`} data-joyride-id="jr-edit-nfd">
            <Button variant="gradient">
              <HiPencil className="-ml-1 mr-2 h-5 w-5 text-white" aria-hidden="true" />
              Edit
            </Button>
          </Link>
          <Link href={`/sell/${nfd.name}`}>
            <Button className="py-2 group whitespace-nowrap">
              <MdOutlineSell
                className="-ml-1 mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-400"
                aria-hidden="true"
              />
              List for sale
            </Button>
          </Link>
          <Link href={`/transfer/${nfd.name}`}>
            <Button className="py-2 group whitespace-nowrap">
              <HiOutlineSwitchHorizontal
                className="-ml-1 mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-400"
                aria-hidden="true"
              />
              Transfer
            </Button>
          </Link>
        </>
      )
    }

    return (
      <Link href={`/offer/${nfd.name}`}>
        <Button className="py-2 group whitespace-nowrap">
          <HiOutlineChatAlt2
            className="-ml-1 mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-400"
            aria-hidden="true"
          />
          Make an offer
        </Button>
      </Link>
    )
  }

  return (
    <>
      <div>
        <Banner nfd={nfd} />
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="-mt-12 sm:-mt-16 flex items-end space-x-1 sm:space-x-4 xl:-mt-20 xl:items-center">
            <Avatar
              key={nfd.name}
              asaId={nfd.properties?.verified?.avatarasaid}
              imageSrc={getAvatarURL(nfd)}
              alt="avatar"
              verified={isAvatarVerified(nfd)}
            />
            <div className="flex-1 min-w-0 flex items-center justify-between sm:pb-2 xl:pb-0 space-x-2">
              <div className="xl:mt-16">
                <Donations nfd={nfd} />
              </div>
              <div className="md:hidden flex justify-stretch">
                <ProfileMenu nfd={nfd} context={context} />
              </div>
              <div className="hidden md:flex justify-stretch space-x-3 xl:mt-16">
                {renderButtons()}

                <div className="px-2">
                  <ProfileMenu nfd={nfd} context={context} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <HeaderGrid className="grid grid-cols-1 md:grid-cols-[1fr_16rem] mt-3 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 w-full">
        <HeaderName className="min-w-0 mb-5">
          <div className="flex items-center justify-between">
            <button
              type="button"
              className="text-2xl sm:text-3xl tracking-tight leading-normal font-bold text-gray-900 truncate rounded-sm pl-1 -ml-1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 group dark:text-gray-100"
              data-clipboard-text={nfd.name}
              onClick={copyToClipboard}
            >
              {nfd.name}
              <BiCopy className="hidden lg:inline ml-3 h-6 w-6 text-white group-hover:text-gray-400 group-focus:text-gray-400 dark:text-gray-900 dark:group-hover:text-gray-500 dark:group-focus:text-gray-500" />
            </button>
          </div>
          {nfd?.properties?.userDefined?.name && (
            <h3 className="text-sm text-gray-400 sm:text-base sm:mt-1.5 truncate dark:text-gray-500">
              {nfd.properties.userDefined.name}
            </h3>
          )}
        </HeaderName>
        <HeaderBio>
          {nfd.properties?.userDefined?.bio && (
            <div className="prose prose-gray text-sm sm:text-base md:max-w-full mb-5 dark:prose-invert">
              <Linkify showIcon decorate>
                <p className="whitespace-pre-line">{nfd.properties?.userDefined.bio}</p>
              </Linkify>
            </div>
          )}
        </HeaderBio>
        <HeaderLinks>
          {hasVerifiedFields && (
            <div className="mb-5">
              <Links nfd={nfd} />
            </div>
          )}
        </HeaderLinks>
        <HeaderAside>
          <Badges nfd={nfd} />
        </HeaderAside>
      </HeaderGrid>

      <PurchasedTip showTip={showTip} />
    </>
  )
}
