import type { IconType } from 'react-icons'
import { BsImage } from 'react-icons/bs'
import { HiUserCircle } from 'react-icons/hi'
import { HiOutlineCheckCircle } from 'react-icons/hi'
import { Field } from './ActionMenu.types'

export const ICONS: { [key in Field | 'default']: IconType } = {
  avatar: HiUserCircle,
  banner: BsImage,
  default: HiOutlineCheckCircle
}
