import Link from 'next/link'
import { BiLinkExternal } from 'react-icons/bi'
import AlgoAddress from 'components/AlgoAddress'
import Alert from 'components/Alert'
import { Context } from 'components/DetailView/DetailView.types'
import { DOCS_URL } from 'src/data/constants'
import type { NfdRecord } from 'api/api-client'

interface VerifiedAddressCardProps {
  nfd: NfdRecord
  context: Context
}

export default function VerifiedAddressCard({ nfd, context }: VerifiedAddressCardProps) {
  const renderAddresses = () => {
    if (!nfd.caAlgo || nfd.caAlgo.length === 0) {
      return (
        <div className="py-4 sm:py-5 px-4 sm:px-6">
          <Alert
            type="info"
            title="No addresses linked"
            message={
              <span>
                There are no verified addresses linked with this NFD yet.{' '}
                {context === Context.Manage && (
                  <a
                    href={`${DOCS_URL}/guides/manage-nfds/setting-addresses`}
                    className="inline-flex items-center font-semibold text-gray-900 hover:text-brand-500 no-underline whitespace-nowrap group dark:font-medium dark:text-brand-500 dark:hover:text-brand-600"
                    target="_blank"
                    rel="noreferrer"
                  >
                    About linked addresses
                    <BiLinkExternal className="mx-0.5 h-4 w-4 text-gray-400 group-hover:text-gray-400/75 dark:text-gray-500 dark:group-hover:text-gray-500" />
                  </a>
                )}
              </span>
            }
          />
        </div>
      )
    }

    return (
      nfd.caAlgo.length > 0 && (
        <div className="text-sm text-gray-900">
          <ul
            role="list"
            className="max-w-full bg-white divide-y divide-gray-200 dark:bg-gray-850 dark:divide-gray-850"
          >
            {nfd.caAlgo.map((address) => (
              <li key={address} className="min-w-0 px-5 py-2 flex items-center text-sm">
                <AlgoAddress
                  address={address}
                  copy
                  link
                  logo
                  expand
                  isDeposit={address === nfd.depositAccount}
                />
              </li>
            ))}
          </ul>
        </div>
      )
    )
  }

  return (
    <div className="break-inside-avoid-column">
      <div className="bg-white shadow overflow-hidden rounded-lg dark:bg-gray-850 dark:highlight">
        <div className="py-4 border-b border-gray-200 px-4 sm:px-6 dark:border-gray-750/75">
          <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div className="ml-4 mt-2">
              <h3 className="text-md leading-6 font-medium text-gray-900 dark:text-gray-300">
                Verified Addresses
              </h3>
            </div>
            <div className="ml-4 mt-2 flex-shrink-0">
              {context === Context.Manage && (
                <Link
                  href={`/manage/${nfd.name}?view=addresses`}
                  className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-brand-500 hover:bg-brand-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 -my-1"
                >
                  Manage
                </Link>
              )}
            </div>
          </div>
        </div>
        {renderAddresses()}
      </div>
    </div>
  )
}
