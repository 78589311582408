import { Disclosure } from '@headlessui/react'
import { useCallback, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { AiFillBank } from 'react-icons/ai'
import { HiBan, HiChevronUp, HiLink } from 'react-icons/hi'
import { RiTwitterFill } from 'react-icons/ri'
import PriceTag from 'components/PriceTag'
import Button from 'components/Button'
import LookupInput from 'components/LookupInput'
import UserThumbnail from 'components/UserThumbnail'
import UsdPrice from 'components/UsdPrice'
import AlgoPrice from 'components/AlgoPrice'
import AssetDetails from 'components/DetailView/Assets/AssetPreview/AssetDetails'
import TokenImage from 'components/DetailView/Assets/AssetPreview/TokenImage'
import AssetMedia from 'components/AssetMedia'
import LoadingDots from 'components/LoadingDots'
import Alert from 'components/Alert'
import Callout from 'components/Callout'
import ToggleGift from './ToggleGift'
import Warning from '../Warning'
import useSaleForm from './Form.hooks'
import useVaultAssets from 'api/hooks/useVaultAssets'
import galleryImageLoader from 'helpers/galleryImageLoader'
import {
  classNames,
  convertAlgosToMicroalgos,
  formatAssetBalance,
  getNfdUrl,
  getTwitterShareLink,
  isAssetNft
} from 'helpers/utilities'
import { copyToClipboard } from 'helpers/copyToClipboard'
import type { NfdRecord } from 'types/api'
import type { Asset } from 'types/assets'

type SaleFormProps = {
  nfd: NfdRecord
  activeAddress: string | undefined
  prevPathname?: string
  reservedFor?: string
  sellAmount?: string
}

export default function Form({
  nfd,
  activeAddress,
  prevPathname,
  reservedFor,
  sellAmount
}: SaleFormProps) {
  const {
    isDirty,
    isValid,
    price,
    handleChangeReserveAddress,
    handleClickCancelTransfer,
    handleGoBack,
    handleToggleGift,
    isGift,
    reservedAddress,
    isForSale,
    isLoading,
    isCancelTransferLoading,
    handleSubmit
  } = useSaleForm(nfd, reservedFor, sellAmount)
  const { showVaultInfo, assets, isLoading: isVaultAssetsLoading, error } = useVaultAssets(nfd)

  const [isDetailsOpen, setIsDetailsOpen] = useState<Record<number, boolean> | null>(null)

  const handleSetIsDetailsOpen = useCallback(
    (assetId: number, isOpen: boolean) => {
      setIsDetailsOpen(
        assets
          ? assets.reduce((acc, asset) => {
              if (asset.id === assetId) {
                acc[asset.id] = isOpen
              } else {
                acc[asset.id] = false
              }
              return acc
            }, {} as Record<number, boolean>)
          : null
      )
    },
    [assets]
  )

  useEffect(() => {
    if (assets) {
      setIsDetailsOpen(
        assets.reduce((acc, asset) => {
          acc[asset.id] = false
          return acc
        }, {} as Record<number, boolean>)
      )
    }
  }, [assets])

  const vaultAddress = nfd.nfdAccount

  const getVaultAmount = (asset: Asset) => {
    const vaultAmount = asset.amounts.find((amount) => amount.account === vaultAddress)?.amount

    if (!vaultAmount) return null

    return formatAssetBalance(vaultAmount, asset.decimals, true, true, 10)
  }

  const renderPreview = (asset: Asset) => {
    if (!isAssetNft(asset.totalCreated, asset.decimals) && !asset.imageUrl) {
      return <TokenImage />
    }

    return (
      <AssetMedia
        src={asset.imageUrl}
        alt={asset.id.toString()}
        className="object-cover w-full h-full"
        loader={galleryImageLoader}
        sizes="32px"
        fill
        options={{ showVideoIcon: false, stateBgColor: 'bg-gray-500/5 dark:bg-gray-500/5' }}
        videoJsOptions={{
          preload: isMobile ? 'auto' : 'metadata',
          controls: isMobile,
          fluid: true,
          fill: true
        }}
      />
    )
  }

  const renderAssetDetails = (asset: Asset) => {
    const isNft = isAssetNft(asset.totalCreated, asset.decimals)
    const totalAmount = asset.amounts.find((amount) => amount.account === vaultAddress)?.amount || 0

    return (
      <AssetDetails
        open={isDetailsOpen?.[asset.id] || false}
        setOpen={(open) => handleSetIsDetailsOpen(asset.id, open)}
        nfd={nfd}
        asset={asset}
        isNft={isNft}
        isAccountsOpen={false}
        totalAmount={totalAmount}
        handleClickSetField={() => null}
        handleClickSendAsset={() => null}
        handleClickMoveToVault={async () => void 0}
        handleClickMoveToDepositAccount={async () => void 0}
        handleClickFilterAccount={() => null}
        canSetField={false}
        canSendAsset={false}
        canMoveToVault={false}
        canMoveToDepositAccount={false}
      />
    )
  }

  const renderVaultAssets = () => {
    const showVaultAssets = showVaultInfo && !!assets && assets.length > 0

    if (!showVaultAssets) return null

    if (isVaultAssetsLoading) {
      return (
        <div className="flex items-center justify-center h-[72px]">
          <LoadingDots />
        </div>
      )
    }

    if (error) {
      return (
        <div>
          <Alert type="error" title={`Error fetching vault assets`} error={error} />
        </div>
      )
    }

    return (
      <Callout theme="blue">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg px-1 py-2 text-left font-medium">
                <span className="inline-flex items-center">
                  <AiFillBank className="h-5 w-5 mr-2" aria-hidden="true" /> Vault Assets (
                  {assets.length})
                </span>
                <HiChevronUp
                  className={classNames(open ? 'transform rotate-180' : '', 'h-6 w-6')}
                  aria-hidden="true"
                />
              </Disclosure.Button>
              <Disclosure.Panel>
                <div className="mt-6 space-y-2.5 max-h-48 overflow-y-auto pr-6">
                  {assets.map((asset) => (
                    <div key={asset.id}>
                      <button
                        type="button"
                        className="group flex items-center w-full"
                        onClick={() => handleSetIsDetailsOpen(asset.id, true)}
                      >
                        <div className="relative flex-shrink-0 w-8 h-8 rounded-full overflow-hidden bg-gray-500/10">
                          {renderPreview(asset)}
                        </div>
                        <div className="ml-3 flex-1 flex items-center justify-between min-w-0 text-sm font-medium text-gray-900 dark:text-gray-200">
                          <p className="truncate font-medium group-hover:text-brand-500">
                            {asset.name}
                          </p>
                          <span className="ml-4 font-mono">{getVaultAmount(asset)}</span>
                        </div>
                      </button>
                      {renderAssetDetails(asset)}
                    </div>
                  ))}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </Callout>
    )
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <h1 className="text-2xl sm:text-3xl font-extrabold tracking-tight text-gray-900 truncate dark:text-gray-100">
          {nfd.name}
        </h1>

        <div className="mt-2 sm:mt-3 space-y-8 sm:space-y-6">
          {isForSale && (
            <div>
              <h2 className="sr-only">Price</h2>
              <div className="flex items-center">
                <PriceTag
                  price={convertAlgosToMicroalgos(Number(price))}
                  className="pl-3 pr-3 text-xl"
                />
                <UsdPrice
                  price={convertAlgosToMicroalgos(Number(price))}
                  className="text-right ml-2"
                />
              </div>
            </div>
          )}

          {nfd.seller && (
            <div>
              <h3 className="text-sm text-gray-500 mb-1">Owner</h3>
              <div className="flex">
                <UserThumbnail
                  address={nfd.seller}
                  className="inline-flex min-w-0 pr-2"
                  fallbackClassName="font-medium bg-orange-50 text-orange-700 px-2 py-0.5 rounded"
                />
              </div>
            </div>
          )}

          {nfd.reservedFor && (
            <div>
              <h3 className="text-sm text-gray-500 mb-1">Reserved for</h3>
              <div className="flex">
                <UserThumbnail address={nfd.reservedFor} className="inline-flex min-w-0 pr-2" />
              </div>
            </div>
          )}

          {renderVaultAssets()}

          <div className="border-t border-gray-200 pt-6 dark:border-gray-750/75">
            <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
              {isForSale ? 'Manage Transfer' : 'Transfer NFD'}
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {isForSale ? (
                'Update the transfer address, or cancel the transfer'
              ) : (
                <>
                  Send this NFD to a specified account.{' '}
                  <strong className="font-medium text-gray-600 dark:text-gray-400">
                    A transfer fee of <AlgoPrice price={1000000} /> is required
                  </strong>
                  .
                </>
              )}
            </p>
          </div>

          {/* Warn user that transferring clears all metadata, locks root if unlocked (small screens only) */}
          <Warning nfd={nfd} className="md:hidden" />

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-400">
              Transfer to
            </label>
            <div className="mt-1">
              <LookupInput
                value={reservedAddress}
                onChange={handleChangeReserveAddress}
                exclude={activeAddress}
              />
            </div>
            <p className="mt-2 text-sm text-gray-500" id="transfer-reserved-description">
              Enter an Algorand address or NFD
            </p>
          </div>
          <div>{!isForSale && <ToggleGift isGift={isGift} handleChange={handleToggleGift} />}</div>
        </div>

        {isForSale ? (
          <div className="mt-8 sm:mt-12 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <Button
              type="submit"
              disabled={!isDirty || !isValid || isLoading || isCancelTransferLoading}
              variant="gradient"
              className="w-full py-3 px-8 sm:col-start-2"
              size="lg"
            >
              Update transfer
            </Button>
            <Button
              onClick={handleClickCancelTransfer}
              disabled={isLoading || isCancelTransferLoading}
              className="mt-3 w-full py-3 px-8 sm:mt-0 sm:col-start-1 focus:ring-red-500"
              size="lg"
            >
              <HiBan className="-ml-1 mr-3 h-5 w-5 text-red-500" aria-hidden="true" />
              Cancel transfer
            </Button>
          </div>
        ) : (
          <div className="mt-8 sm:mt-12 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <Button
              type="submit"
              disabled={!isDirty || !isValid || isLoading}
              variant="gradient"
              className="w-full py-3 px-8 sm:col-start-2"
              size="lg"
            >
              Transfer
            </Button>
            <Button
              onClick={() => handleGoBack(prevPathname)}
              disabled={isLoading}
              className="mt-3 w-full py-3 px-8 sm:mt-0 sm:col-start-1"
              size="lg"
            >
              Go back
            </Button>
          </div>
        )}
      </form>

      {isForSale && (
        <section aria-labelledby="details-heading" className="mt-12">
          <h2 id="details-heading" className="sr-only">
            Additional details
          </h2>

          <div className="border-t divide-y divide-gray-200 dark:border-gray-750/75 dark:divide-gray-750/75">
            <div className="py-6">
              <div className="flex items-center space-x-3">
                <Button
                  className="group"
                  data-clipboard-text={getNfdUrl(nfd.name)}
                  onClick={copyToClipboard}
                >
                  <HiLink
                    className="-ml-1 mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Copy link
                </Button>
                <a
                  className="inline-flex items-center justify-center shadow-sm font-medium px-4 py-2 text-sm rounded-md border border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                  href={getTwitterShareLink(nfd.name, true)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <RiTwitterFill
                    className="-ml-1 mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Share to Twitter
                </a>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  )
}
